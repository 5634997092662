enum SocketEventType {
  update = 'update',
  create = 'create',
  delete = 'delete',
}

export class MakeSocketName {
  static contactName(type: SocketEventType, name: string, id?: string) {
    return [type, name, id].filter(Boolean).join('-');
  }

  static onCreate(name: string, id?: string) {
    return this.contactName(SocketEventType.create, name, id);
  }

  static onUpdate(name: string, id?: string) {
    return this.contactName(SocketEventType.update, name, id);
  }

  static onDelete(name: string, id?: string) {
    return this.contactName(SocketEventType.delete, name, id);
  }
}
