import QRCode from 'react-qr-code';
import * as S from './styles';
import { BackButton } from '../ButtonBack';
import { money } from 'utils/money';
import { Text } from '../Text/Text';
import { PaymentModalProps } from './types';
import pixLogo from '../../assets/logo_pix.png';
import Button from '../Button';

const PaymentModal = (props: PaymentModalProps) => {
  const { payment, onClose } = props;
  const { value: amount, Transactions, PaymentMethodPix } = payment;
  const [{ Pix }] = Transactions;

  return (
    <S.Container>
      <S.BackButtonWrapper>
        <BackButton onClick={onClose} />
      </S.BackButtonWrapper>

      <Text variant="text">{PaymentMethodPix?.instructions}</Text>

      {Boolean(+amount) && (
        <>
          <Text variant="info">Valor a pagar:</Text>
          <Text variant="title">{money(amount / 100)}</Text>
        </>
      )}

      <QRCode value={Pix.qrCode} />

      <S.PixLogo src={pixLogo} alt="" />

      <Button onClick={onClose} style={{ color: '#FFFFFF' }}>
        Pronto
      </Button>
    </S.Container>
  );
};

export { PaymentModal };
