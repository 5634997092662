import * as yup from 'yup';

export function validateEmail(email?: string): Promise<string> {
  const errorMessage = 'Insira um email valido';
  return yup
    .string()
    .required(errorMessage)
    .email(errorMessage)
    .validate(email);
}

export function validateCode(confimationCode?: string) {
  return yup.string().required('Insira um codigo').validate(confimationCode);
}

export function validatePass(props: { pass: string; confirmPass: string }) {
  const schema = yup.object().shape({
    pass: yup
      .string()
      .min(6, 'Pelo menos 6 digitos')
      .required('Preencdha o campo senha'),
    confirmPass: yup
      .string()
      .oneOf([yup.ref('pass')], 'As senhas precisam ser iguais')
      .required('Preencha o campo confirmação de senha'),
  });

  return schema.validate(props);
}
