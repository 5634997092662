import styled from "styled-components"

export const Label = styled.label`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px;
    padding: 6px 20px;
    border-bottom: 1px solid #45454520;
    cursor: pointer;

`
export const Content = styled.div`
    display: flex; 
    flex-direction: column;
`
export const LabelName = styled.span`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
`
export const LabelPrice = styled.span`
    font-size: 13px; 
    margin-left: 6px;
    color: ${({ hasDiscount, theme: { colors } }) => hasDiscount ? colors.success : colors.textSecondary};
    font-weight: 700;
`
export const LabelOldPrice = styled.span`
    font-size: 12px; 
    margin-left: 6px;
    text-decoration: line-through;
    color: ${({ theme }) => theme.colors.textSecondary};
    font-weight: 700;
`
export const Input = styled.input``
