import React from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import * as S from './styles';
import { useTab } from '../../hooks/tabIndex';
import { useCart } from '../../hooks/useCart';
import { useGroup } from '../../hooks/group';
import { useTheme } from 'styled-components';
import { useConfig } from '../../hooks/config';
import { DELIVERY_TYPES } from 'entities/delivery-types';

export const FloatCartButton = ({ hasTab = true, ...rest }) => {
  const { setTab } = useTab();
  const { setGroup } = useGroup();
  const { cart } = useCart();
  const { colors } = useTheme();
  const { config } = useConfig();

  const { IsOpen, TipoEntregaCheck } = config;
  const onlyView = TipoEntregaCheck?.split(';').includes(DELIVERY_TYPES.view);
  const storeIsOpen = Boolean(IsOpen);

  const handleClick = () => {
    setGroup(null);
    setTab(3);
  };

  if (onlyView || !storeIsOpen) {
    return null;
  }

  return (
    <S.Container hasTab={hasTab} {...rest} onClickCapture={handleClick}>
      <S.IconWrapper>
        <FaShoppingCart size={26} color={colors.text} />
      </S.IconWrapper>
      {cart.length > 0 && <S.Badge>{cart.length}</S.Badge>}
    </S.Container>
  );
};
