import { shade } from 'polished';
import styled from 'styled-components';

export const ContainerInputButton = styled.div`
  display: flex;
  border-radius: 10px;
  border: 2px solid;
  border-color: ${(props) => props.color ?? '#bcbcbc'};
`;

export const InputButtonProp = styled.input`
  flex: 2;
  font-size: 16px;
  font-weight: 600;
  margin: 2px;
  border: 0;
  width: 34.3vh;
`;

export const ButtonProp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 6vh;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: ${(props) => props.color ?? '#000'};
  font-size: 30px;
  color: #fff;
  cursor: pointer;

  &:active {
    background: ${(props) => shade(0.2, props.color ?? '#000')};
    transition: 0.2s;
  }
`;
