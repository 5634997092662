import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0%;
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  box-shadow: 0px 2px 4px 0px #000000;
  justify-content: space-around;
  align-items: center;
`;

export const ItemMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.main};
  opacity: ${(props) => (props.selected ? 1 : 0.45)};
`;

export const ItemIcon = styled.div``;

export const ItemName = styled.div`
  font-size: 10px;
`;

export const CircleItemsCart = styled.div`
  position: absolute;
  top: -4px;
  right: 10px;
  display: flex;
  justify-content: center;
  font-weight: 800;
  font-size: 12px;
  align-items: center;
  border-radius: 50%;
  background: #ff0000;
  height: 16px;
  width: 16px;
  padding: 1px;
  color: #fff;
`;
