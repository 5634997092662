import { moeda } from '../../../../utils/functions';
import { FinishedButton, FinishedButtonText } from './styles';

type Props = {
  onSubmit: () => void;
  total: number;
};

export function SubmitButtom({ onSubmit, total }: Props) {
  return (
    <FinishedButton onClick={onSubmit}>
      <FinishedButtonText>Enviar Pedido</FinishedButtonText>
      <FinishedButtonText>{moeda(total)}</FinishedButtonText>
    </FinishedButton>
  );
}
