import React, { useCallback, useState, useMemo } from 'react';
import { useEffect } from 'react';
import Loading from '../../components/Loading';
import { useConfig } from '../../hooks/config';
import api, { IdEmpresa } from '../../services/api';
import {
  CategoryImageSearch,
  CategorySearch,
  CategoryTitleSearch,
  Container,
  ContainerCategorySearch,
  ContainerProducts,
  ContainerSearch,
  Form,
  InputSearch,
  Title,
} from './styles';
import { useGroup } from '../../hooks/group';
import { debounce } from '../../utils/functions';
import ListProducts from '../../components/ListProducts';
import { FloatCartButton } from '../../components/FloatCartButton';
import { getGroups } from '../../httpRequests/group/getGroups';
import { FallbackImage } from '../../components/FallbackImage';
import { getSizesByCompany } from '../../httpRequests/sizes/getSizesByCompany';
import { filterGroupsBySchedules } from '../../utils/handlers/filterGroupsBySchedules';
import { Group } from '../../entities/group.entity';

const SearchProduct = () => {
  const { config } = useConfig();
  const { setGroup } = useGroup();
  const [search, setSearch] = useState('');
  const [groups, setGroups] = useState<Group[]>([]);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [sizes, setSizes] = useState([]);

  const baseUrl = api.getUri();

  const update = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getGroups(IdEmpresa);
      const filteredData = await filterGroupsBySchedules(data, IdEmpresa);

      setGroups(filteredData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [getGroups, IdEmpresa]);

  const searchProducts = useCallback(
    async (value: string) => {
      if (value) {
        await api
          .post('/produto/listarProdutosDaPesquisa', {
            IdEmpresa: IdEmpresa,
            Descricao: value,
          })
          .then((res) => {
            setProducts(res.data.length > 0 ? res.data : []);
            setLoadingSearch(false);
          });
      }
    },
    [IdEmpresa],
  );

  const getSizes = async () => {
    const data = await getSizesByCompany(IdEmpresa);
    setSizes(data);
  };

  const onChangeSearch = useMemo(
    () =>
      debounce((value: string) => {
        setLoadingSearch(true);
        setSearch(value);
        searchProducts(value);
      }, 1500),
    [],
  );

  useEffect(() => {
    update();
    getSizes();
  }, []);

  return (
    <Container>
      <ContainerSearch>
        <Form>
          <InputSearch
            placeholder="Buscar produto..."
            onChange={(e) => onChangeSearch(e.target.value)}
          />
        </Form>
      </ContainerSearch>
      {!search && <Title>Categorias</Title>}
      {loading && <Loading loading={loading} />}
      {!loading && (
        <>
          {!search ? (
            <ContainerCategorySearch>
              {groups.map((group) => (
                <CategorySearch
                  key={group.Id}
                  onClick={() => {
                    setGroup(group);
                  }}
                >
                  {group.ImagemURL ? (
                    <CategoryImageSearch src={`${baseUrl}${group.ImagemURL}`} />
                  ) : (
                    <FallbackImage size={150} style={{ margin: 5 }} />
                  )}
                  <CategoryTitleSearch>{group.Descricao}</CategoryTitleSearch>
                </CategorySearch>
              ))}
            </ContainerCategorySearch>
          ) : (
            <ContainerProducts>
              {loadingSearch && <Loading loading={loadingSearch} />}
              {!loadingSearch && (
                <ListProducts products={products} searchProduct="" />
              )}
            </ContainerProducts>
          )}
        </>
      )}

      <FloatCartButton />
    </Container>
  );
};

export default SearchProduct;
