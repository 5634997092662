import { notifications } from '../../utils/notifications';
import api, { IdEmpresa } from '../../services/api';
import * as yup from 'yup';
import { Order } from '../../entities/Order.entity';

export async function getOrdersByPhone(
  phoneNumber: string,
): Promise<Order[] | null> {
  try {
    const validation = yup.string();
    await validation.validate(phoneNumber);

    const { data } = await api.get<Order[]>('pedido/consultarPorCelular', {
      params: {
        IdEmpresa,
        Celular: phoneNumber,
      },
    });

    if (!data) {
      throw new Error(`Orders for ${phoneNumber} not found`);
    }

    return data;
  } catch (error: any) {
    console.log({ error });
    notifications.error(error.response?.data?.message);
    return null;
  }
}
