import { Product } from '../../entities/Product.entity';
import api from '../../services/api';

export async function getPromotionProducts(companyId: string | null) {
  try {
    if (!companyId || Number.isNaN(Number(companyId))) {
      return;
    }

    const { data } = await api.get<Product[]>(
      `/produto/listar-promocoes/${companyId}`,
    );

    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
}
