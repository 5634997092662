import React from 'react';
import { useConfig } from '../../hooks/config';
import { ButtonProp, ContainerInputButton, InputButtonProp } from './styles';

const InputButton = ({
  placeholder,
  value,
  name,
  onChange,
  type,
  disabled,
  onClick,
  iconButton,
}) => {
  const { config } = useConfig();
  return (
    <ContainerInputButton color={config.Tema}>
      <InputButtonProp
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        type={type ?? 'text'}
        disabled={disabled}
      />
      <ButtonProp onClick={onClick} color={config.Tema}>
        {iconButton}
      </ButtonProp>
    </ContainerInputButton>
  );
};

export default InputButton;
