import React, { useEffect } from 'react';
import { useGroup } from '../../hooks/group';
import { useProduct } from '../../hooks/useProduct';
import { useTab } from '../../hooks/tabIndex';
import Login from '../../pages/Login';
import AddProduct from '../../pages/AddProduct';
import Catalog from '../../pages/Catalog';
import Info from '../../pages/Info';
import ProductsGroup from '../../pages/ProductsGroup';
import SearchProduct from '../../pages/SearchProduct';
import ShoppingCart from '../../pages/ShoppingCart';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import { Container } from './styles';
import CreateAccount from '../../pages/CreateAccount';
import ConsultDelivery from '../../pages/ConsultDelivery';
import Account from '../../pages/Account';
import FinishOrder from '../../pages/FinishOrder';
import { useConfig } from '../../hooks/config';
import CompaniesCatalg from '../../pages/CompaniesCatalog';
import { Route } from './Route/Route';
import { ForgotPassword } from 'pages/ForgotPassword/ForgotPassword';
import { screens } from './screens';
import { useCompany } from '../../hooks/useCompany';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';

const defaultOptions = { header: true, menu: true };

function withLayout(Component = () => <div />, options = defaultOptions) {
  return () => {
    return (
      <>
        {options.header ? <Header /> : null}
        {Component ? <Component /> : null}
        {options.menu ? <Menu /> : null}
      </>
    );
  };
}

const Tabs = () => {
  const { tab, finish } = useTab();
  const { product } = useProduct();
  const { group } = useGroup();
  const { config } = useConfig();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loadCompany, loading } = useCompany();
  const modalOpen = Boolean(group || product || finish);

  const defaultProps = {
    current: tab,
    modalOpen: modalOpen,
  };

  useEffect(() => {
    if (id) {
      loadCompany(id, navigate);
    }
  }, [id]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Container>
      <Route
        id={screens.home}
        component={
          config.CatalogoEmpresas !== 'True'
            ? withLayout(Catalog)
            : withLayout(CompaniesCatalg, { menu: false, header: true })
        }
        {...defaultProps}
      />

      <Route
        id={screens.search_product}
        component={withLayout(SearchProduct, { menu: true, header: false })}
        {...defaultProps}
      />

      <Route id={screens.cart} component={ShoppingCart} {...defaultProps} />

      <Route
        id={screens.login}
        component={withLayout(Login)}
        {...defaultProps}
      />

      <Route id={screens.info} component={withLayout(Info)} {...defaultProps} />

      <Route
        id={screens.create_account}
        component={CreateAccount}
        {...defaultProps}
      />

      <Route
        id={screens.consult_order}
        component={withLayout(ConsultDelivery, { menu: true, header: false })}
        {...defaultProps}
      />

      <Route
        id={screens.account}
        component={withLayout(Account, { menu: true, header: false })}
        {...defaultProps}
      />

      <Route
        id={screens.forgot_password}
        component={ForgotPassword}
        {...defaultProps}
      />

      {/** ----------- Modals below ----------- */}

      <Route
        isModal
        open={Boolean(group && !product)}
        component={ProductsGroup}
        {...defaultProps}
      />

      <Route
        isModal
        open={Boolean(product)}
        component={AddProduct}
        {...defaultProps}
      />

      <Route isModal open={finish} component={FinishOrder} {...defaultProps} />
    </Container>
  );
};
export default Tabs;
