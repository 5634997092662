import React from 'react';
import { Container, LabelProps } from './styles';

const Label = ({ text, styles }) => {
  return (
    <Container style={styles}>
      <LabelProps>{text}</LabelProps>
    </Container>
  );
};

export default Label;
