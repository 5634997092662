import { useEffect, useState } from 'react';
import * as S from '../styles';
import api from '../../../services/api';
import { useTheme } from 'styled-components';
import { moeda } from 'utils/functions';
import { Flavors } from './Flavors';
import { MountingAdditional } from './MountingAdditional';

type ProductProps = {
  product: {
    IdBorda: string;
    IdProduto: string;
    Descricao: string;
    Tamanho: string;
    TipoProduto: string;
    Montagem: string;
    Quantidade: number;
    Sabores: string;
    Adicionais: string;
    Observacao: string;
    Valor: number;
  };
  order: {
    Id: string;
  };
};

type Border = {
  IdBorda?: string;
  Descricao?: string;
  Preco?: number;
  PrecoDesconto?: number;
};

export const Product = ({ product, order }: ProductProps) => {
  const [border, setBorder] = useState<Border>({});
  const { colors } = useTheme();

  const getItemBorderPrice = () => {
    if (Number(border?.PrecoDesconto) > 0) {
      return Number(border?.PrecoDesconto);
    }

    return Number(border?.Preco) || 0;
  };

  useEffect(() => {
    if (product?.IdBorda) {
      api
        .get(`/pedido-borda/encontrar/${product.IdBorda}`)
        .then((res) => {
          if (!res.data?.Id) return console.log('Borda não encontrada');

          setBorder(res.data);
        })
        .catch((error) => console.log(error));
    }
  }, [product?.IdBorda]);

  return (
    <S.Product key={`${product.IdProduto}-${order.Id}`}>
      <S.DescriptionProduct>{product.Descricao}</S.DescriptionProduct>
      {['TAMANHO', 'PIZZA', 'PORCAO', 'PREPARADO'].includes(
        product.TipoProduto,
      ) &&
        product.Tamanho && (
          <S.SizeProduct>Tamanho: {product.Tamanho}</S.SizeProduct>
        )}
      {border?.IdBorda && (
        <S.Borda>
          <S.BordaDescription>Borda: </S.BordaDescription>
          {border?.Descricao} - {moeda(getItemBorderPrice())}
        </S.Borda>
      )}
      <S.MountingsProduct>
        <MountingAdditional
          value={product.Montagem}
          type="m"
          qtd={product.Quantidade}
        />
      </S.MountingsProduct>
      <S.FlavorProductContainer>
        <Flavors data={product.Sabores} />
      </S.FlavorProductContainer>
      <S.AdditionalsProductContainer>
        <MountingAdditional
          value={product.Adicionais}
          type="a"
          qtd={product.Quantidade}
        />
      </S.AdditionalsProductContainer>
      {product.Observacao && (
        <S.ObservationProductContainer>
          <S.LabelProduct color={colors.main}>Observação:</S.LabelProduct>
          <S.ValueProduct>{product.Observacao}</S.ValueProduct>
        </S.ObservationProductContainer>
      )}
      <S.UnityProduct>
        <S.LabelProduct color={colors.main}>Quantidade:</S.LabelProduct>{' '}
        <S.ValueProduct>{Number(product.Quantidade)}</S.ValueProduct>
      </S.UnityProduct>
      <S.PriceProduct>
        <S.LabelProduct color={colors.main}>Preço unitário:</S.LabelProduct>
        <S.ValueProduct>{moeda(product.Valor)}</S.ValueProduct>
      </S.PriceProduct>
      <S.TotalProduct color={colors.title}>
        Total produto: {moeda(product.Valor * product.Quantidade)}
      </S.TotalProduct>
    </S.Product>
  );
};
