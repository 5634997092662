import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding: 5px;
  width: 100%;
  border-radius: 8px;
  margin: 5px 0px;
`;

export const TextName = styled.div`
  display: flex;
  font-size: 15px;
  font-weight: bold;
  align-items: center;
  margin-left: 10px;
`;

export const TextValue = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ sizeProp }) => (sizeProp ? 20 : 12)}px;
  cursor: pointer;
  margin-right: 10px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
`;

export const InfoDelivery = styled.div``;
