import { User } from '../../entities/user.entity';
import api from '../../services/api';

export async function getUser(id: string): Promise<User | null> {
  try {
    const response = await api.get<User>(`usuario/encontrar/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
