import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 20px;

  background-color: ${({ theme }) => theme.colors.background};
`;

export const BackButtonWrapper = styled.div`
  align-self: start;
`;

export const PixLogo = styled.img`
  width: 150px;
  margin: 20px;
`;
