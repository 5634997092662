import React, { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { Container } from './styles';

export const ScrollToTopButton = ({
  left,
  top,
  right,
  bottom,
  style,
  scrollOptions,
  ...props
}) => {
  const [isHiddend, setIsHiddend] = useState(true);

  const styles = { left, top, right, bottom, ...style };

  const handleScroll = () => {
    window.scrollTo(scrollOptions || { top: 0, left: 0, behavior: 'smooth' });
  };

  const scrollListener = () => {
    if (window.scrollY > window.screen.height * 0.6) {
      setIsHiddend(false);
    } else {
      setIsHiddend(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  return (
    <Container
      onClick={handleScroll}
      isHiddend={isHiddend}
      style={styles}
      type="button"
      {...props}
    >
      <FaArrowUp size={20} color="#ffffff" />
    </Container>
  );
};
