import React, { useCallback, useEffect, useState } from 'react';
import { useConfig } from '../../hooks/config';
import {
  FaFacebookSquare,
  FaInstagram,
  FaWhatsapp,
  FaPhoneAlt,
} from 'react-icons/fa';
import Button from '../../components/Button';
import { AvailabilityContainer } from '../../components/Header/styles';
import { redirecionarWhats, redirecionarTelefone } from '../../utils/functions';
import api, { IdEmpresa } from '../../services/api';
import {
  CompaniesContainer,
  Company,
  CompanyContacts,
  CompanyDescription,
  CompanyInfo,
  CompanyLogo,
  CompanyTitle,
  Contact,
  ListCompanies,
  Logo,
} from './styles';
import Dropdown from '../../components/Dropdown';
import CompaniesProducts from './CompaniesProducts';

const CompaniesCatalg = () => {
  const { config } = useConfig();
  const [companies, setCompanies] = useState([]);
  const [types, setTypes] = useState([]);
  const [segment, setSegment] = useState('');
  const [searchProducts, setSearchProducts] = useState(false);

  const baseUrl = api.getUri();

  const redirectCompany = useCallback((company_id) => {
    window.location.pathname = `/${company_id}`;
  }, []);

  const loadCompanies = useCallback(async () => {
    await api
      .get(`/tipo/listarTiposEmpresa/${IdEmpresa}`)
      .then((response) => {
        setTypes(response.data);
      })
      .catch((err) => console.log(err));

    await api
      .get(`/empresa-catalogo/listarEmpresas/${IdEmpresa}`)
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((err) => console.log(err));
  }, [IdEmpresa]);

  useEffect(() => {
    loadCompanies();
  }, []);

  return (
    <CompaniesContainer>
      <Button fullScreen onClick={() => setSearchProducts(true)}>
        Buscar produtos
      </Button>
      <Dropdown
        value={segment}
        onChange={(e) => setSegment(e.target.value)}
        list={types}
        objListId="Id"
        objListLabel="Descricao"
        fullScreen
        nameDefaultOption="Selecione o Segmento"
      />
      <ListCompanies>
        {companies
          .filter((x) => {
            if (segment === x.Tipo.toString()) {
              return x;
            }
            if (segment === '') {
              return x;
            }
          })
          .map((company) => (
            <Company key={company.Id}>
              <CompanyLogo
                onClick={() => redirectCompany(company.IdEmpresaCatalogo)}
              >
                <Logo
                  src={
                    company.Logomarca?.startsWith('data:image/')
                      ? company.Logomarca
                      : `${baseUrl}${company.Logomarca}`
                  }
                />
              </CompanyLogo>
              <CompanyInfo>
                <CompanyTitle
                  color={config.Tema}
                  onClick={() => redirectCompany(company.IdEmpresaCatalogo)}
                >
                  {company.NomeFantasia}
                </CompanyTitle>
                {company.Aberto === 'Sim' ? (
                  <AvailabilityContainer color="#008000">
                    Aberto
                  </AvailabilityContainer>
                ) : (
                  <AvailabilityContainer color="#dc3545">
                    Fechado
                  </AvailabilityContainer>
                )}
                <CompanyDescription
                  onClick={() => redirectCompany(company.IdEmpresaCatalogo)}
                >
                  {`Endereço: ${company.Cep} - ${
                    company.Logradouro ? company.Logradouro + ',' : ''
                  } ${company.Numero + ','} ${
                    company.Complemento ? company.Complemento + ',' : ''
                  } ${company.Cidade}-${company.Estado}`}
                </CompanyDescription>
                <CompanyContacts>
                  {company.Facebook && (
                    <Contact href={`https://facebook.com/${company.Facebook}`}>
                      <FaFacebookSquare color="#4267b2" size={20} />
                    </Contact>
                  )}
                  {company.Instagram && (
                    <Contact
                      href={`https://instagram.com/${company.Instagram}`}
                    >
                      <FaInstagram color="#DD2A7B" size={20} />
                    </Contact>
                  )}
                  {company.Celular ? (
                    <Contact onClick={() => redirecionarWhats(company.Celular)}>
                      <FaWhatsapp color="#34af23" size={20} /> {company.Celular}
                    </Contact>
                  ) : (
                    <Contact
                      onClick={() => redirecionarTelefone(company.Telefone)}
                    >
                      <FaPhoneAlt size={15} /> {company.Telefone}
                    </Contact>
                  )}
                </CompanyContacts>
              </CompanyInfo>
            </Company>
          ))}
      </ListCompanies>
      {searchProducts && (
        <CompaniesProducts
          show={searchProducts}
          close={() => setSearchProducts(false)}
        />
      )}
    </CompaniesContainer>
  );
};

export default CompaniesCatalg;
