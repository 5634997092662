import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerConsultDelivery = styled.div`
  display: flex;
  height: 80px;
  flex-direction: row;
  width: 100%;
`;

export const InputConsult = styled.input`
  width: 100%;
  border-radius: 18px;
  padding: 10px 16px;
  font-size: 25px;
  margin: 10px;
  margin-right: 0px;
  flex: 4;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.shapeGray};
`;

export const ButtonConsult = styled.div`
  width: 60px;
  height: 60px;
  margin: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.main};
  border-radius: 18px;
  font-size: 40px;
  color: #fff;

  &:active {
    background: ${({ theme }) => shade(0.2, theme.colors.main ?? `#000`)};
    transition: 0.1s;
  }
`;

export const ContainerDeliveries = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 80px;
`;

export const ContainerDeliveriesEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
  flex-direction: column;
`;

export const EmptyTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
  font-size: 24px;
`;

export const EmptyDescription = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-weight: bold;
  font-size: 14px;
  margin: 10px;
  text-align: center;
`;
