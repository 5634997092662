export const getPriceSmaller = (sizesPizzas = [], product = {}) => {
  const product_id = product.Id ?? '';
  let smaller = 0;
  let discoutPrice = 0;
  let withoutDiscount = 0;

  const productSizes = sizesPizzas.filter(
    (item) => item.IdProduto === product_id,
  );

  productSizes.forEach((item, index) => {
    const { Preco, PrecoDesconto } = item;

    if (Number(Preco) === 0) return;

    if (index === 0) {
      smaller = Preco;
      discoutPrice = PrecoDesconto;
      return;
    }

    if (Preco < smaller) {
      smaller = Preco;
    }
    if (PrecoDesconto < discoutPrice) {
      discoutPrice = PrecoDesconto;
    }
  });

  if (product?.HasDesconto) {
    withoutDiscount = smaller;
    smaller = discoutPrice ?? 0;
  }

  return { smaller, withoutDiscount };
};

export const priceSmallerPortionView = (product) => {
  if (product.PorcaoMeia !== 0) {
    return product.PorcaoMeia;
  }
  return product.PorcaoInteira;
};

export const priceSmallerSizeView = (product, sizes) => {
  const HasDiscount = product?.HasDesconto;
  let smallSizePrice = Infinity;
  let smallerSizeDiscountPrice = Infinity;

  sizes.forEach((size) => {
    const currentPrice = Number(size.Preco);
    const currentDiscountPrice = Number(size.PrecoDesconto);

    if (currentPrice < smallSizePrice) {
      smallSizePrice = currentPrice;
    }

    if (currentDiscountPrice < smallerSizeDiscountPrice) {
      smallerSizeDiscountPrice = currentDiscountPrice;
    }
  });

  if (HasDiscount && smallerSizeDiscountPrice !== Infinity) {
    return {
      smallPrice: Number(smallerSizeDiscountPrice),
      withoutDiscount: Number(smallSizePrice),
    };
  }

  if (smallSizePrice !== Infinity) {
    return { smallPrice: smallSizePrice };
  }

  return { smallPrice: Number(product.PrecoVenda) };
};

export const verifyQtNotCostAddShoppingCart = (
  mounting,
  checkMountings,
  size,
) => {
  let qtdSemCusto = mounting.QtdSemCusto;
  const finalMounting = checkMountings
    .filter(
      (mnt) =>
        mnt.idMontagem === mounting.IdMontagemPrato && mnt.quantidade > 0,
    )
    .map((mnt) => {
      if (qtdSemCusto >= mnt.quantidade) {
        qtdSemCusto -= mnt.quantidade;
        return { ...mnt, precoMontagem: 0 };
      }

      const diferenca = mnt.quantidade - qtdSemCusto;
      qtdSemCusto = 0;

      if (mnt.tipoProduto === 'TAMANHO' && size === 'PP') {
        return { ...mnt, precoMontagem: diferenca * +mnt.tamanhoPP };
      }
      if (mnt.tipoProduto === 'TAMANHO' && size === 'Pequeno') {
        return { ...mnt, precoMontagem: diferenca * +mnt.tamanhoPequeno };
      }
      if (mnt.tipoProduto === 'TAMANHO' && size === 'Medio') {
        return { ...mnt, precoMontagem: diferenca * +mnt.tamanhoMedio };
      }
      if (mnt.tipoProduto === 'TAMANHO' && size === 'Grande') {
        return { ...mnt, precoMontagem: diferenca * +mnt.tamanhoGrande };
      }
      if (mnt.tipoProduto === 'TAMANHO' && size === 'ExtraGrande') {
        return { ...mnt, precoMontagem: diferenca * +mnt.tamanhoExtraGrande };
      }

      return { ...mnt, precoMontagem: diferenca * +mnt.preco };
    });
  return finalMounting;
};

export const verifyQtNoCost = (monting, checkMountings, size) => {
  let qtNoCost = monting.QtdSemCusto;
  const valorMnt = checkMountings
    .filter(
      (mnt) => mnt.idMontagem === monting.IdMontagemPrato && mnt.quantidade > 0,
    )
    .reduce((acc, atual) => {
      if (!qtNoCost) {
        return acc + atual.quantidade * atual.preco;
      }

      if (atual.quantidade > qtNoCost) {
        const diferenca = atual.quantidade - qtNoCost;
        qtNoCost = 0;

        if (atual.tipoProduto === 'TAMANHO' && size === 'PP') {
          return acc + atual.tamanhoPP * diferenca;
        }
        if (atual.tipoProduto === 'TAMANHO' && size === 'Pequeno') {
          return acc + atual.tamanhoPequeno * diferenca;
        }
        if (atual.tipoProduto === 'TAMANHO' && size === 'Medio') {
          return acc + atual.tamanhoMedio * diferenca;
        }
        if (atual.tipoProduto === 'TAMANHO' && size === 'Grande') {
          return acc + atual.tamanhoGrande * diferenca;
        }
        if (atual.tipoProduto === 'TAMANHO' && size === 'ExtraGrande') {
          return acc + atual.tamanhoExtraGrande * diferenca;
        }
        return acc + atual.preco * diferenca;
      }

      qtNoCost -= atual.quantidade;

      return acc;
    }, 0);
  return valorMnt;
};

export const validateObligatoriness = (
  mountingObligatoriness = [],
  checkMountings = [],
) => {
  let checkByMountId = {};
  let result = true;

  checkMountings.forEach(({ idMontagem, quantidade }) => {
    const hasKey = Object.keys(checkByMountId).some(
      (key) => key === idMontagem,
    );

    if (hasKey) {
      checkByMountId = {
        ...checkByMountId,
        [idMontagem]: (checkByMountId[idMontagem] += quantidade),
      };
      return;
    }

    checkByMountId = { ...checkByMountId, [idMontagem]: quantidade };
  });

  mountingObligatoriness.forEach((oblig) => {
    if (!result) return;

    const { IdMontagemPrato, QtdMin } = oblig;
    const quantity = checkByMountId[IdMontagemPrato] ?? 0;
    const min = QtdMin ?? 0;

    if (quantity < min) {
      result = false;
    }
  });

  return result;
};

export const validationQuatityminimal = (
  mountings,
  checkedMountings,
  mountingObligatoriness,
) => {
  let counter = 0;
  mountings.forEach((mont) => {
    let qtdMin = 0;
    checkedMountings.map((check) => {
      if (check.idMontagem === mont.IdMontagemPrato) {
        qtdMin += parseInt(check.quantidade);
      }
    });
    if (qtdMin >= mont.QtdMin && mont.QtdMin > 0) {
      counter += 1;
    }
  });
  if (mountingObligatoriness.length === counter) {
    return true;
  }
  return false;
};

export const verifyPriceBigerPizza = (size, pizzas, pricePizza) => {
  let bigerBrotinho = 0,
    bigerPequena = 0,
    bigerMedia = 0,
    bigerGrande = 0,
    bigerGG = 0,
    bigerBig = 0,
    bigerFamilia = 0;
  for (let i = 0; i < pizzas.length; i += 1) {
    if (size === 'Brotinho' && pizzas[i].precoBrotinho > bigerBrotinho) {
      bigerBrotinho = parseFloat(pizzas[i].precoBrotinho);
    }
    if (size === 'Pequena' && pizzas[i].precoPequena > bigerPequena) {
      bigerPequena = parseFloat(pizzas[i].precoPequena);
    }
    if (size === 'Media' && pizzas[i].precoMedia > bigerMedia) {
      bigerMedia = parseFloat(pizzas[i].precoMedia);
    }
    if (size === 'Grande' && pizzas[i].precoGrande > bigerGrande) {
      bigerGrande = parseFloat(pizzas[i].precoGrande);
    }
    if (size === 'Gigante' && pizzas[i].precoGG > bigerGG) {
      bigerGG = parseFloat(pizzas[i].precoGG);
    }
    if (size === 'Big' && pizzas[i].precoBig > bigerBig) {
      bigerBig = parseFloat(pizzas[i].precoBig);
    }
    if (size === 'Familia' && pizzas[i].precoFamilia > bigerFamilia) {
      bigerFamilia = parseFloat(pizzas[i].precoBig);
    }
  }
  if (size === 'Brotinho' && parseFloat(pricePizza) < bigerBrotinho) {
    return bigerBrotinho;
  }
  if (size === 'Pequena' && parseFloat(pricePizza) < bigerPequena) {
    return bigerPequena;
  }
  if (size === 'Media' && parseFloat(pricePizza) < bigerMedia) {
    return bigerMedia;
  }
  if (size === 'Grande' && parseFloat(pricePizza) < bigerGrande) {
    return bigerGrande;
  }
  if (size === 'Gigante' && parseFloat(pricePizza) < bigerGG) {
    return bigerGG;
  }
  if (size === 'Big' && parseFloat(pricePizza) < bigerBig) {
    return bigerBig;
  }
  if (size === 'Familia' && parseFloat(pricePizza) < bigerFamilia) {
    return bigerFamilia;
  }
  return pricePizza;
};

export const verifyPriceDivided = (size, pizzas, pricePizza) => {
  let priceDivided = 0;
  const qtdSabores = pizzas.length + 1;
  pizzas.forEach((pizza) => {
    if (size === 'Brotinho') {
      priceDivided += parseFloat(pizza.precoBrotinho / qtdSabores);
    }
    if (size === 'Pequena') {
      priceDivided += parseFloat(pizza.precoPequena / qtdSabores);
    }
    if (size === 'Media') {
      priceDivided += parseFloat(pizza.precoMedia / qtdSabores);
    }
    if (size === 'Grande') {
      priceDivided += parseFloat(pizza.precoGrande / qtdSabores);
    }
    if (size === 'Gigante') {
      priceDivided += parseFloat(pizza.precoGG / qtdSabores);
    }
    if (size === 'Big') {
      priceDivided += parseFloat(pizza.precoBig / qtdSabores);
    }
    if (size === 'Familia') {
      priceDivided += parseFloat(pizza.precoFamilia / qtdSabores);
    }
  });

  priceDivided += parseFloat(pricePizza / qtdSabores);
  return priceDivided;
};

export const verifySizePizzaBlockedDelivery = (config, cart) => {
  let locks = JSON.parse(config?.TamanhosPizzasBloqueadosEntregas ?? false);
  if (locks) {
    let pizzas = cart.filter((product) => product.tipo === 'PIZZA');
    let finded = false;
    pizzas.forEach((item) => {
      if (locks.brotinho && item.tamanho === 'Brotinho') {
        finded = true;
      }
      if (locks.pequena && item.tamanho === 'Pequena') {
        finded = true;
      }
      if (locks.media && item.tamanho === 'Media') {
        finded = true;
      }
      if (locks.grande && item.tamanho === 'Grande') {
        finded = true;
      }
      if (locks.gigante && item.tamanho === 'Gigante') {
        finded = true;
      }
      if (locks.big && item.tamanho === 'Big') {
        finded = true;
      }
      if (locks.familia && item.tamanho === 'Familia') {
        finded = true;
      }
    });
    return finded;
  }
  return false;
};
