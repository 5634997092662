import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 90vh;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerButtonReload = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const ButtonReload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.main ?? '#000'};
  width: 3.2rem;
  height: 2.5rem;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin: 0px 10px;
  cursor: pointer;

  &:active {
    background: ${({ theme }) => shade(0.4, theme.colors.main ?? '#000')};
    transition: 0.1s;
  }
`;
