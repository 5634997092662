import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerButtonBack = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
`;

export const ContainerCreateAccount = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  padding: 0 20px;
  height: 85vh;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: 45px 0px 0px;
  gap: 12px;
`;

export const ContainerTitleCreate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TitleCreate = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.title};
`;

export const ContainerSignIn = styled.div``;

export const DescriptionSignIn = styled.div`
  font-size: 12px;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.text};
`;

export const DescriptonLink = styled.a`
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  font-weight: 700;
`;
