import { useContext } from 'react';
import { CartContext } from '../../contexts/cart.context';
import { useState, useEffect } from 'react';
import { getShoppingCartLocalStorage } from '../../utils/get-shopping-cart';
import { TCartItem } from 'entities/cart-item.entity';
import {
  getItemAdditionalPrice,
  getItemBorderPrice,
  getItemMountingPrice,
  mapState,
} from './utils';

export type TCart = {
  cart?: Array<TCartItem>;
  total?: number;
  removeItem?: (index: number) => void;
  addItem?: (item: TCartItem) => void;
  increaseItem?: (index: number) => void;
  decreaseItem?: (index: number) => void;
  clearCart?: () => void;
};

export const useCartContext = (): TCart => {
  const [cart, setCart] = useState<TCartItem[]>([]);
  const [total, setTotal] = useState(0);

  const increaseItem = (index: number) => {
    setCart((state) => state.map(mapState({ index, value: 1 })));
  };

  const decreaseItem = (index: number) => {
    setCart((state) => state.map(mapState({ index, value: -1 })));
  };

  const addItem = (item: TCartItem) => setCart((state) => [...state, item]);

  const removeItem = (index: number) => {
    setCart((prev) => {
      const newState = prev.filter((_, i) => index !== i);
      localStorage.setItem('produtos', JSON.stringify(newState));
      return newState;
    });
  };

  const clearCart = () => setCart([]);

  const totalCart = () => {
    let basePrice = 0;
    let additionalPrice = 0;
    let mountingsPrice = 0;
    let bordersPrice = 0;

    cart?.forEach((item) => {
      basePrice += item.preco * Number(item.quantidade);
      additionalPrice += getItemAdditionalPrice(item);
      bordersPrice += getItemBorderPrice(item);
      mountingsPrice += getItemMountingPrice(item);
    });

    const sum = basePrice + additionalPrice + mountingsPrice + bordersPrice;
    setTotal(sum);
  };

  useEffect(() => {
    setCart(getShoppingCartLocalStorage() ?? []);
  }, []);

  useEffect(() => {
    totalCart();
    localStorage.setItem('produtos', JSON.stringify(cart));
  }, [cart]);

  return {
    cart,
    total,
    removeItem,
    addItem,
    increaseItem,
    decreaseItem,
    clearCart,
  };
};

export function useCart() {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('CartContext must be used within an CartContext');
  }

  return context;
}
