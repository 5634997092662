import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 10px;
  padding-bottom: 40px 0px;
`;

export const ContainerButtonAdd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

export const ButtonAdd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color ?? '#000'};
  width: 7rem;
  height: 2.5rem;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;

  &:active {
    background: ${(props) => shade(0.4, props.color ?? '#000')};
    transition: 0.1s;
  }
`;

export const ContainerAddress = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 10px 0px;
  padding-bottom: 90px;
`;

export const MyAddress = styled.div`
  display: flex;
  background: #fff;
  margin: 5px 0px;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;

  &:active {
    background: ${shade(0.3, '#fff')};
    transition: 0.1s;
  }
`;

export const AddressDescription = styled.div`
  font-size: 18px;
  font-weight: 500;
`;
