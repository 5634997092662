import { ConfigProvider } from '../hooks/config';
import { TabProvider } from '../hooks/tabIndex';
import { CompanyProvider } from '../hooks/useCompany';
import { ProductProvider } from '../hooks/useProduct';
import { GroupProvider } from '../hooks/group';
import { SchedulesProvider } from '../hooks/schedules';
import { CartProvider } from '../contexts/cart.context';
import { AuthProvider } from '../hooks/auth';
import { ThemeProvides } from './ThemeProvides/ThemeProvider.provider';
import { ProductsProvider } from '../contexts/products.context';
import { GroupsProvider } from '../hooks/useGroups';
import { SizesProvider } from '../hooks/useSizes';

export const providers = [
  AuthProvider,
  ConfigProvider,
  ThemeProvides,
  CompanyProvider,
  SchedulesProvider,
  GroupProvider,
  CartProvider,
  TabProvider,
  ProductsProvider,
  ProductProvider,
  GroupsProvider,
  SizesProvider,
];
