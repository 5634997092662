import api from '../../services/api';

type Props = {
  companyId: number;
  userId?: string;
  phone?: string;
};

export async function getAvailableUserGoal(props: Props) {
  try {
    const { phone, userId, companyId } = props;

    if (!phone && !userId) {
      return null;
    }

    const id = userId || phone;

    const url = `/metas/disponivel/${id}?empresa=${companyId}`;

    const { data } = await api.get(url);

    return data;
  } catch (error) {
    return null;
  }
}
