import { daysOfWeekCapitalize } from '../../../utils/constants/daysOfWeekList';

export function getMinMaxTime(
  scheduleDate: string,
  scheduleTime: string,
  schedules: [],
) {
  const currentDay =
    daysOfWeekCapitalize[new Date(`${scheduleDate} ${scheduleTime}`).getDay()];

  const daySchedules = schedules.filter(
    (schedule: { DiaSemana: string }) => schedule.DiaSemana === currentDay,
  );

  const res: { min: Date | null; max: Date | null } = {
    min: null,
    max: null,
  };

  daySchedules.forEach((curr: { Inicio: string; Fim: string }) => {
    const [firstHour, firstMinutes] = curr.Inicio.split(':');
    const [lastHour, lastMinutes] = curr.Fim.split(':');
    const [year, month, day] = scheduleDate.split('-');

    const baseDate = new Date();
    baseDate.setFullYear(Number(year));
    baseDate.setMonth(Number(month) - 1);
    baseDate.setDate(Number(day));

    const firstDate = new Date(baseDate);
    const lastDate = new Date(baseDate);

    firstDate.setHours(Number(firstHour));
    firstDate.setMinutes(Number(firstMinutes));
    lastDate.setHours(Number(lastHour));
    lastDate.setMinutes(Number(lastMinutes));

    if (!res.min || firstDate < res.min) {
      res.min = firstDate;
    }

    if (!res.max || lastDate > res.max) {
      res.max = lastDate;
    }
  });

  return res;
}
