import { useTheme } from 'styled-components';
import { useConfig } from '../../hooks/config';
import { ContainerCheckBox, InputCheckProp, LabelCheckProp } from './styles';

const CheckBox = ({
  placeholder,
  onChange = () => {},
  onChangeValue = () => {},
  disabled,
  checked,
  ...res
}) => {
  const { config } = useConfig();
  const { colors } = useTheme();

  return (
    <ContainerCheckBox>
      <LabelCheckProp>
        <InputCheckProp
          onChange={(event) => {
            console.log({ event });
            onChange?.(event);
            onChangeValue?.(event.target.checked);
          }}
          type={'checkbox'}
          color={config.Tema || colors.main}
          disabled={disabled}
          checked={checked}
          {...res}
        />
      </LabelCheckProp>
    </ContainerCheckBox>
  );
};

export default CheckBox;
