import { createContext, PropsWithChildren } from 'react';
import { TCart, useCartContext } from '../hooks/useCart';

export const CartContext = createContext<TCart>({});

export const CartProvider = ({ children }: PropsWithChildren) => {
  const context = useCartContext();
  return (
    <CartContext.Provider value={context}>{children}</CartContext.Provider>
  );
};
