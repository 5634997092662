import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  PropsWithChildren,
  useRef,
} from 'react';
import { getCompany } from '../httpRequests/company/getCompany';
import { NavigateFunction } from 'react-router-dom';

type TCompanyContext = {
  company: any;
  loadCompany: (companyId: string, navigate: NavigateFunction) => Promise<void>;
  loading: boolean;
};

const CompanyContext = createContext<TCompanyContext>({
  company: null,
  loadCompany: Promise.resolve,
  loading: true,
});

export const CompanyProvider = ({ children }: PropsWithChildren) => {
  const loadCompanyCalledRef = useRef(false);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(null);

  const loadCompany = useCallback(
    async (companyId: string, navigate: NavigateFunction) => {
      if (!companyId) return;
      if (loadCompanyCalledRef.current) return;
      loadCompanyCalledRef.current = true;

      const data = await getCompany(Number(companyId));

      if (data && data.Ativo) {
        setLoading(false);
        setCompany(data);
        return;
      }

      navigate('/');
    },
    [],
  );

  return (
    <CompanyContext.Provider value={{ company, loadCompany, loading }}>
      {children}
    </CompanyContext.Provider>
  );
};

export function useCompany() {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error('CompanyContext must be used within an CompanyContext');
  }
  return context;
}
