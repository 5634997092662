import { Product } from '../../entities/Product.entity';
import { Addittional } from '../../entities/Addittional.entity';
import api from '../../services/api';

export async function getAddittionalsByProduct(
  productId: string,
): Promise<Addittional[]> {
  try {
    const res = await api.get<Product[]>(
      `/produto/listarAdicionaisPorProduto/${productId}`,
    );

    if (!res.data || !Array.isArray(res.data)) {
      return [];
    }

    const parsedData = res.data?.map((add, i) => {
      return {
        id: add.Id,
        idProd: add.Id,
        codigoPDV: add.CodigoPDV,
        label: add.Descricao,
        preco: add.PrecoVenda,
        grupo: add.Grupo,
        check: false,
        amount: 0,
        image: add?.Imagem ?? '',
      };
    });

    return parsedData;
  } catch (error) {
    console.log(error);
    return [];
  }
}
