import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  position: sticky;
  bottom: 0px;
  right: 0px;
  left: 0px;

  padding: 16px;
  border-radius: 10px;
  gap: 12px;

  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
`;
