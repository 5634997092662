import React, { useEffect } from 'react';
import { MdOutlineListAlt, MdSearch, MdInfo, MdLogin } from 'react-icons/md';
import { HiUser } from 'react-icons/hi';
import { useConfig } from '../../hooks/config';
import { useTab } from '../../hooks/tabIndex';
import { Container, ItemMenu, ItemName } from './styles';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

const Menu = () => {
  const sizeIcons = 28;
  const { config } = useConfig();
  const { tab, setTab } = useTab();
  const { isAuth } = useAuth();
  const baseUrl = api.getUri();

  useEffect(() => {
    if (config.Logomarca && Object.values(config).length) {
      document.querySelector("link[rel*='icon']").href =
        config.Logomarca.startsWith('data:image/')
          ? config.Logomarca
          : `${baseUrl}${config.Logomarca}`;
    }
  }, [config]);

  return (
    <Container>
      <ItemMenu
        color={config.Tema}
        onClick={() => setTab(1)}
        selected={tab === 1}
      >
        <MdOutlineListAlt size={sizeIcons} />
        <ItemName>Cardapio</ItemName>
      </ItemMenu>
      <ItemMenu
        color={config.Tema}
        onClick={() => setTab(2)}
        selected={tab === 2}
      >
        <MdSearch size={sizeIcons} />
        <ItemName>Buscar</ItemName>
      </ItemMenu>
      {!isAuth ? (
        <ItemMenu
          color={config.Tema}
          onClick={() => setTab(4)}
          selected={tab === 4}
        >
          <MdLogin size={sizeIcons} />
          <ItemName>Entrar</ItemName>
        </ItemMenu>
      ) : (
        <ItemMenu
          color={config.Tema}
          onClick={() => setTab(8)}
          selected={tab === 8}
        >
          <HiUser size={sizeIcons} />
          <ItemName>Meus Pedidos</ItemName>
        </ItemMenu>
      )}
      <ItemMenu
        color={config.Tema}
        onClick={() => setTab(5)}
        selected={tab === 5}
      >
        <MdInfo size={sizeIcons} />
        <ItemName>Info</ItemName>
      </ItemMenu>
    </Container>
  );
};

export default Menu;
