import styled from "styled-components";

export const Container = styled.div`
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: ${({ hasTab }) => hasTab ? '60px' : '20px'};
    right: 14px;
    border-radius: 30px;
    background: ${({ theme }) => theme.colors.backgroundSecondary};
    box-shadow: -1px 1px 6px 0px #00000045;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: -3px;
`;

export const Badge = styled.div`
    background-color: #d72222;
    color: #ffffff;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: 0px;
    font-size: 14px;
`;
