export const defatultStatusState = {
  waiting: false,
  confirm: false,
  sending: false,
};

export const mapStatusState = new Map([
  ['PENDENTE', { waiting: true }],
  ['ANDAMENTO', { waiting: true, confirm: true }],
  ['ENTREGA', { waiting: true, confirm: true, sending: true }],
  ['CONCLUIDO', { waiting: true, confirm: true, sending: true }],
]);
