import { CSSProperties } from 'styled-components';

export const font_size = {
  xxs: { fontSize: 12 },
  xs: { fontSize: 16 },
  sm: { fontSize: 20 },
  md: { fontSize: 24 },
  lg: { fontSize: 28 },
  xl: { fontSize: 32 },
  xxl: { fontSize: 36 },
};

const titleCss: CSSProperties = {
  fontWeight: 'bold',
  color: '#000000',
  textTransform: 'capitalize',
};

type Variants = {
  title: CSSProperties;
  sub_title: CSSProperties;
  sub_title_1: CSSProperties;
  text: CSSProperties;
  info: CSSProperties;
};

export const variants_styles: Variants = {
  title: {
    ...titleCss,
    ...font_size.xl,
  },
  sub_title: {
    ...titleCss,
    ...font_size.lg,
  },
  sub_title_1: {
    ...titleCss,
    ...font_size.md,
  },
  text: {
    color: '#222222',
    ...font_size.sm,
  },
  info: {
    fontWeight: 'bold',
    color: '#454545',
    ...font_size.xs,
  },
};
