export const commonColors = {
  main: '#4BFF47',

  success: '#03B252',
  danger: '#F65555',
  alert: '#fa7116',

  shape: '#FFFFFF',
  shapeDark: '#29292E',

  transparent: 'transparent',
};
