import { useEffect, useRef } from 'react';
import { useConfig } from '../hooks/config';

import ReactPixel from 'react-facebook-pixel';

function trackFBEvent(trackId = '', data = undefined) {
  if (!trackId) {
    return;
  }

  let dataParsed;

  if (data && Object.values(data).length > 0) {
    dataParsed = data;
  }

  console.log(`TRACK EVENT: ${trackId}`);

  ReactPixel.track(trackId, dataParsed);
}

// function factoryTrackEvent<T>(trackId) { data: T } - tipar com generic
function factoryTrackEvent(trackId) {
  return function (data) {
    trackFBEvent(trackId, data);
  };
}

const FBPixelTrackEvent = {
  addPaymentInfo: factoryTrackEvent('AddPaymentInfo'), // credit card
  addToCart: factoryTrackEvent('AddToCart'),
  addToWishlist: factoryTrackEvent('AddToWishlist'),
  completeRegistration: factoryTrackEvent('CompleteRegistration'),
  contact: factoryTrackEvent('Contact'),
  customizeProduct: factoryTrackEvent('CustomizeProduct'),
  Donate: factoryTrackEvent('Donate'),
  findLocation: factoryTrackEvent('FindLocation'),
  initiateCheckout: factoryTrackEvent('InitiateCheckout'),
  lead: factoryTrackEvent('Lead'),
  purchase: factoryTrackEvent('Purchase'),
  schedule: factoryTrackEvent('Schedule'),
  search: factoryTrackEvent('Search'),
  startTrial: factoryTrackEvent('StartTrial'),
  submitApplication: factoryTrackEvent('SubmitApplication'),
  subscribe: factoryTrackEvent('Subscribe'),
  viewContent: factoryTrackEvent('ViewContent'),
};

function InitializeFaceBookPixel() {
  const pixelRef = useRef(false);
  const { config } = useConfig();
  const pixel = config?.FaceBookPixel;

  useEffect(() => {
    if (pixel && !pixelRef.current) {
      pixelRef.current = true;

      console.log('INITIALIZE FACEBOOK PIXEL');

      ReactPixel.init(pixel);
      ReactPixel.pageView();
    }
  }, [pixel]);

  return pixelRef.current;
}

export { InitializeFaceBookPixel, FBPixelTrackEvent };
