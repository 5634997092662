import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) =>
    shade(0.05, theme.colors.backgroundSecondary)};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
`;

export const ShoppingCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ShoppingCartList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width:  100%;
`;

export const NoProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  align-items: center;
  justify-content: center;
`;

export const NotProductsTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
  font-size: 24px;
`;

export const NotProductDescription = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-weight: bold;
  font-size: 14px;
  margin: 10px;
  text-align: center;
`;

export const ConsultOrder = styled.button`
  display: flex;
  border: none;
  font-family: 'Poppins';
  color: #fff;
  background: ${({ theme }) => theme.colors.main};
  padding: 10px;
  border-radius: 5px;
`;
