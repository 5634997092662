import { shade } from 'polished';
import styled from 'styled-components';

export const ItemContainer = styled.div``;

export const TitleMounitngsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #bcbcbc;
`;

export const TitleMounitngs = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  font-weight: 700;
`;

export const ListMountings = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 4px;
  overflow: auto;
  border: 1px solid #dddbdb;
  border-radius: 5px;
`;

export const Mounting = styled.div``;

export const MountingTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  /* border-bottom: 2px dashed #000; */
  color: ${(props) => props.color ?? '#000'};
`;

export const MountingDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MountingDescription = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.color ?? '#000'};
`;

export const ProductsMountingContainer = styled.div`
  padding: 0 10px;
`;

export const ProductMounting = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const ProductMountingInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProductMountingDescrition = styled.div``;

export const ProductMountingPrice = styled.div`
  font-weight: 600;
  color: ${(props) => props.color ?? '#000'};
`;

export const ProductsMountigOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProductMountingButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #bcbcbc; */
  border-radius: 5px;
`;

export const ProductMountingButton = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 5px;
  margin: 5px 5px;
  color: #fff;
  background: ${(props) => props.color ?? '#bcbcbc'};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;

  &:active {
    background: ${shade(0.4, '#bcbcbc')};
  }
`;

export const ProductMountingQuantity = styled.div`
  font-weight: 700;
`;

export const ProductMountingsOptions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
`;

export const MountingOption = styled.button`
  width: 100px;
  padding: 15px;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-family: 'Poppins';
  background: ${(props) => props.color ?? '#bcbcbc'};

  &:active {
    background: ${(props) => shade(0.2, props.color ?? `#bcbcbc`)};
  }
`;

export const ItemsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.shapeGray};
  padding: 6px 10px;
`;

export const ItemsTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemsDescription = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 11px;
`;

export const CardContainer = styled.div`
  display: flex;
  margin: 10px 0;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;

  transition: background 0.3s ease;
`;

export const CardImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 6px;
  margin-right: 6px;
  overflow: hidden;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const CardDetails = styled.div``;

export const CardTitle = styled.span`
  color: ${({ theme }) => theme.colors.text};
`;

export const CardDescription = styled.span``;

export const CardPrice = styled.span`
  color: ${({ theme }) => theme.colors.text};
  padding-right: 8px;
`;

export const CardActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  justify-content: center;
`;

export const CardActions = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  gap: 6px;
`;

export const CardAmount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  background-color: ${({ theme }) => theme.colors.shapeGray};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 4px;
`;

export const ActionIconWrapper = styled.div`
  display: flex;
  padding: 6px;
  filter: ${({ isDisabbled }) => (isDisabbled ? 'grayscale(100%)' : 'none')};
  opacity: ${({ isDisabbled }) => (isDisabbled ? 0.5 : 1)};
`;
