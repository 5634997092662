import api from '../../../../services/api';
import { mapStateDecrement, mapStateIncrement } from './utils';

export const useMountingsInsert = ({ setCheckMountings, checkMountings }) => {
  const verifyQuantitymountingItems = (qtdMax, idMounting) => {
    let cont = 0;

    checkMountings.forEach((check) => {
      if (check.quantidade > 0 && check.idMontagem === idMounting) {
        cont += check.quantidade;
      }
    });

    return cont < qtdMax;
  };

  const addMounting = (prod, mont) => {
    if (verifyQuantitymountingItems(prod.qtdMax, prod.idMontagem)) {
      setCheckMountings((state) => state.map(mapStateIncrement(prod, mont)));
    }
  };

  const remMounting = (prod, mont) => {
    setCheckMountings((state) => state.map(mapStateDecrement(prod, mont)));
  };

  const handleImage = (prod) => {
    const baseUrl = api.getUri();
    return prod?.image ? `${baseUrl}${prod.image}` : null;
  };

  const handleIsDisabled = (mount, check) => {
    let quantitySelected = checkMountings
      .filter((x) => x.idMontagem === mount.IdMontagemPrato)
      .reduce((acc, curr) => (acc += curr.quantidade), 0);

    return quantitySelected >= check.qtdMax;
  };

  return {
    addMounting,
    remMounting,
    handleImage,
    handleIsDisabled,
  };
};
