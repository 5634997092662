import React from 'react';
import { descontoPercentual, moeda } from '../../utils/functions';
import * as S from './styles';

export const ListSelectSize = ({ options, onSelect, value }) => {
  return (
    <>
      {options?.map((item) => (
        <S.Label key={item.value} htmlFor={item.value}>
          <S.Content>
            <S.LabelName>{item.value}</S.LabelName>
            {!item.hasDiscount && !item.hasPercentDiscount && (
              <S.LabelPrice>{moeda(item.price)}</S.LabelPrice>
            )}
            {!!item.hasDiscount && (
              <S.LabelPrice hasDiscount>
                {moeda(item.discountPrice)}
                <S.LabelOldPrice>{moeda(item.price)}</S.LabelOldPrice>
              </S.LabelPrice>
            )}
            {!!item.hasPercentDiscount && (
              <S.LabelPrice hasDiscount>
                {moeda(descontoPercentual(item.price, item.percentDiscount))}
                <S.LabelOldPrice>{moeda(item.price)}</S.LabelOldPrice>
              </S.LabelPrice>
            )}
          </S.Content>
          <S.Input
            type="radio"
            name={item.value}
            id={item.value}
            checked={value === item?.value}
            onChange={() => onSelect(item.value)}
          />
        </S.Label>
      ))}
    </>
  );
};
