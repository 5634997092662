import { ProductSize } from '../../entities/ProductSize.entity';
import api from '../../services/api';

export async function getProductsSizesByProduct(
  productId: string,
): Promise<Array<ProductSize>> {
  try {
    const res = await api.get<ProductSize>(
      `/produto-tamanho/listarPorProduto/${productId}`,
    );

    if (res.data && Array.isArray(res?.data)) {
      return res.data;
    }

    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
}
