import api, { IdEmpresa as companyId } from '../../services/api';

export async function getProductsSizesByCompany() {
  try {
    const res = await api.get(`/produto-tamanho/listarPorEmpresa/${companyId}`);

    if (res.data && Array.isArray(res?.data)) {
      return res.data;
    }

    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
}
