import styled from "styled-components";

export const Container = styled.button`
    display: ${({ isHiddend }) => isHiddend ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #222c;
    border: none;
    transition: opacity 200ms linear;
    cursor: pointer;
`;
