import { useEffect, useId } from 'react';
import { SocketEventNames } from '../enums/SocketEventNames';
import { MakeSocketName } from '../utils/MakeSocketName';
import { openSocket } from '../utils/openSocket';

type Props = {
  onUpdate: Function;
  userPhone?: string;
  userId?: string;
};

export function useOrdersSocket({ onUpdate, userPhone, userId }: Props) {
  function onSocketUpdate() {
    onUpdate?.({ withLoad: false });
  }

  useEffect(() => {
    if (!userPhone && !userId) return;

    const socket = openSocket();

    socket?.on('connect', () => {
      console.info('SOCKET STARTED');
    });

    socket?.on('disconnect', () => {
      console.info('SOCKET DISCONNECTED');
    });

    socket?.on(
      MakeSocketName.onUpdate(SocketEventNames.order, userId || userPhone),
      onSocketUpdate,
    );

    return () => {
      socket?.disconnect();
    };
  }, [userPhone]);
}
