import React, { useEffect } from 'react';
import Border from './Border';
import {
  ItemsBodyContainer,
  ItemsContainer,
  ItemsDescription,
  ItemsHeaderContainer,
  ItemsTitle,
} from './styles';

type Props = {
  containerRef: React.MutableRefObject<any>;
  items: any[];
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  size: any;
  selectedSize: any;
  mountingsRefs: React.MutableRefObject<any[]>;
  additionalRef: React.MutableRefObject<any>;
};

const Borders = ({
  containerRef,
  items,
  setItems,
  size,
  selectedSize,
  mountingsRefs,
  additionalRef,
}: Props) => {
  const itemsCheckedCount = () => {
    const cont = items.filter((x) => x.check);
    return cont.length;
  };

  const checkAdd = (index: number, adc: any) => {
    const ckechedCount = itemsCheckedCount();
    const qtd = 1;
    const check = ckechedCount < qtd ? !adc.check : false;

    if (check) {
      const firstMountRef = mountingsRefs?.current?.at(0);

      if (firstMountRef?.current) {
        firstMountRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }

      if (!firstMountRef?.current && additionalRef.current) {
        additionalRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }

    setItems((prev) =>
      prev.map((item, i) => ({
        ...item,
        check: index !== i ? false : !item.check,
      })),
    );
  };

  useEffect(() => {
    setItems((state) => state.map((item) => ({ ...item, check: false })));
  }, [size]);

  return (
    <ItemsContainer show={items.length > 0} ref={containerRef}>
      <ItemsHeaderContainer>
        <ItemsTitle>Borda:</ItemsTitle>

        <ItemsDescription>(opcional)</ItemsDescription>
      </ItemsHeaderContainer>

      <ItemsBodyContainer>
        {items.map((border, i) => (
          <Border
            border={border}
            index={i}
            checkAdd={checkAdd}
            size={size}
            selectedSize={selectedSize}
          />
        ))}
      </ItemsBodyContainer>
    </ItemsContainer>
  );
}

export default Borders;