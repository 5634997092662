import styled from 'styled-components';
import { BoxCustomProps } from './types';

const px = (value?: number | string) => {
  if (typeof value !== 'number') {
    return value;
  }
  return value ? `${value}px` : undefined;
};

export const Container = styled.div<BoxCustomProps>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  flex: ${({ flex }) => flex};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => px(gap)};
  width: ${({ width }) => px(width)};
  height: ${({ height }) => px(height)};
  background-color: ${({ bgColor }) => bgColor};

  border-radius: ${({ radius }) => px(radius)};

  margin-top: ${({ my, marginTop }) => px(marginTop || my)};
  margin-bottom: ${({ my, marginBottom }) => px(marginBottom || my)};
  margin-right: ${({ mx, marginRight }) => px(marginRight || mx)};
  margin-left: ${({ mx, marginLeft }) => px(marginLeft || mx)};
  margin: ${({ margin }) => px(margin)};

  padding-top: ${({ py, paddingTop }) => px(paddingTop || py)};
  padding-bottom: ${({ py, paddingBottom }) => px(paddingBottom || py)};
  padding-right: ${(props) => px(props.paddingRight || props.px)};
  padding-left: ${(props) => px(props.paddingLeft || props.px)};
  padding: ${({ padding }) => px(padding)};
`;
