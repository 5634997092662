import { IdEmpresa } from '../services/api';

export const getShoppingCartLocalStorage = () => {
  let array = JSON.parse(localStorage.getItem('produtos'));
  const car = [];
  if (array !== null) {
    array.forEach((item) => {
      if (item.idEmpresa === IdEmpresa) {
        car.push(item);
      }
    });
    return car;
  }
};
