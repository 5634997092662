import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import api, { IdEmpresa } from '../services/api';
import { getDay } from '../utils/functions';

const SchedulesContext = createContext({ schedules: [], isOpen: false });

function useSchedulesContext() {
  const [schedules, setSchedules] = useState([]);
  const isOpen = getDay(schedules);

  useEffect(() => {
    if (Number(IdEmpresa)) {
      api
        .get(`/horario/listar/${IdEmpresa}`)
        .then((res) => {
          setSchedules(res.data);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return { schedules, isOpen };
}

export function SchedulesProvider({ children }: PropsWithChildren) {
  const data = useSchedulesContext();
  return (
    <SchedulesContext.Provider value={data}>
      {children}
    </SchedulesContext.Provider>
  );
}

export function useSchedules() {
  const context = useContext(SchedulesContext);

  if (!context) {
    throw new Error('SchedulesContext must be used within an SchedulesContext');
  }

  return context;
}
