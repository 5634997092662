import React, { useState } from 'react';
import api from '../../../../services/api';
import { notification } from '../../../../utils/notifications';
import {
  getMaxQuantity,
  mapStateDecrement,
  mapStateIncrement,
  mapStateReset,
} from './utils';
import { useGroups } from '../../../../hooks/useGroups';
import { Addittional } from '../../../../entities/Addittional.entity';

type UseAdditionalProps = {
  additionals: Addittional[];
  setAdditionals: React.Dispatch<React.SetStateAction<Addittional[]>>;
};

export const useAdditional = ({
  additionals = [],
  setAdditionals = () => {},
}: UseAdditionalProps) => {
  const { addittionalGroups } = useGroups();
  const [noAdditionals, setNoAdditionals] = useState(false);

  const quantitySelected = additionals.reduce(
    (acc, curr) => (acc += curr?.amount ?? 0),
    0,
  );

  const maxQuantity = getMaxQuantity(
    addittionalGroups,
    additionals.at(0)?.grupo,
  );

  const handleImage = (add: Addittional) => {
    const baseUrl = api.getUri();

    return add?.image ? `${baseUrl}${add.image}` : null;
  };

  const verifyMaxQuantity = (add: Addittional) => {
    const productGroup = addittionalGroups.find(
      (group) => group.Id === add?.grupo,
    );
    if (!productGroup) return;

    const { QtdMaxima } = productGroup;

    const onMax = Boolean(QtdMaxima && quantitySelected >= QtdMaxima);

    return onMax;
  };

  const handleIncrement = (add: Addittional) => {
    if (noAdditionals) setNoAdditionals(false);

    const onMax = verifyMaxQuantity(add);

    if (onMax) {
      notification('error', 'Quantidade maxima de adicionais atingida.');
    } else {
      setAdditionals((state) => state.map(mapStateIncrement(add)));
    }
  };

  const handleDecrement = (add: Addittional) => {
    setAdditionals((state) => state.map(mapStateDecrement(add)));
  };

  const handleReset = () => {
    setNoAdditionals(!noAdditionals);

    if (!noAdditionals) {
      setAdditionals((state) => state.map(mapStateReset));
    }
  };

  return {
    quantitySelected,
    maxQuantity,
    noAdditionals,
    handleImage,
    handleIncrement,
    handleDecrement,
    handleReset,
  };
};
