import React, { PropsWithChildren, useMemo } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useConfig } from '../../hooks/config';
import { lightTheme } from '../../styles/themes/light.theme';
import { darkTheme } from '../../styles/themes/dark.theme';

const getTheme = (theme?: 'auto' | 'light' | 'dark') => {
  const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const themes = {
    auto: isDark ? darkTheme : lightTheme,
    light: lightTheme,
    dark: darkTheme,
  };

  return theme && themes[theme] ? themes[theme] : lightTheme;
};

export const ThemeProvides = ({ children }: PropsWithChildren) => {
  const { config } = useConfig();
  const configTheme = config?.TipoTema;
  const colorTheme = config.Tema;

  const theme = useMemo(() => {
    const themeData = getTheme(configTheme);

    const colors = {
      ...themeData.colors,
      main: colorTheme || themeData.colors.main,
    };

    return { ...themeData, colors };
  }, [configTheme, colorTheme, darkTheme, lightTheme]);

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};
