export function getDateByDateAndTimeString(date: string, time?: string) {
  if (!date) {
    return new Date();
  }

  const currentTime = new Date();
  const [year, month, day] = date.split('-');
  const month_index = +month - 1;

  currentTime.setFullYear(+year);
  currentTime.setMonth(month_index);
  currentTime.setDate(+day);

  if (time) {
    const [hour, minutes] = time.split(':');
    currentTime.setHours(+hour);
    currentTime.setMinutes(+minutes);
  }

  return currentTime;
}
