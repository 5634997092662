import React, { useCallback, useEffect } from 'react';
import Title from '../../../components/Title';
import {
  AddressDescription,
  ButtonAdd,
  Container,
  ContainerAddress,
  ContainerButtonAdd,
  MyAddress,
} from './styles';
import Loading from '../../../components/Loading';
import { MdAdd } from 'react-icons/md';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useConfig } from '../../../hooks/config';
import { useState } from 'react';
import api, { IdEmpresa } from '../../../services/api';
import AddAddress from './Add';
import { useAuth } from '../../../hooks/auth';

const Address = ({ visible }) => {
  const { config } = useConfig();
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [myAddress, setMyAddress] = useState([]);
  const [address, setAddress] = useState({});
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [openAddModal, setOpenAddModal] = useState(false);

  const update = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/bairro/listar/${IdEmpresa}`)
      .then((response) => {
        setNeighborhoods(response.data);
      })
      .catch((error) => {
        console.log(
          `Erro ao carregar bairros. ${error.response.data.message}`,
          error,
        );
      });

    await api
      .get(`/endereco/listar/${user?.Id}/${IdEmpresa}`)
      .then((response) => {
        setMyAddress(response.data);
      })
      .catch((error) => {
        console.log(
          `Erro ao carregar seus endereços. ${error.response.data.message}`,
          error,
        );
      });
    setLoading(false);
  }, [user]);

  const toggleAddModal = () => {
    if (openAddModal) {
      setAddress({});
    }
    setOpenAddModal((prev) => !prev);
  };

  useEffect(() => {
    update();
  }, []);

  return (
    <>
      {visible && (
        <Container>
          <Title>Meus Endereços</Title>
          <ContainerButtonAdd>
            <ButtonAdd color={config.Tema} onClick={toggleAddModal}>
              <MdAdd size={20} /> Adicionar
            </ButtonAdd>
          </ContainerButtonAdd>
          {loading && <Loading loading={loading} />}
          {!loading && (
            <ContainerAddress>
              {myAddress.map((ads) => (
                <MyAddress key={ads.Id} onClick={() => setAddress(ads)}>
                  <AddressDescription>
                    <FaMapMarkerAlt color={config.Tema} /> {ads.Descricao}
                  </AddressDescription>
                </MyAddress>
              ))}
            </ContainerAddress>
          )}
        </Container>
      )}
      {openAddModal && (
        <AddAddress
          address={address}
          setMyAddress={setMyAddress}
          config={config}
          neighborhoods={neighborhoods}
          closeModal={toggleAddModal}
        />
      )}
    </>
  );
};

export default Address;
