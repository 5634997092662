import api from '../../services/api';

export async function getSizesByCompany(
  companyId: string | null,
  abortSignal?: AbortSignal,
) {
  try {
    if (!companyId || companyId === 'undefined') return [];

    const { data } = await api.get(
      `produto-tamanho/listarPorEmpresa/${companyId}`,
      { signal: abortSignal },
    );

    return data;
  } catch (error) {
    console.log(error);
  }
}
