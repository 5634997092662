import { Product } from '../../entities/Product.entity';
import api from '../../services/api';

export async function getProductsByGroup(
  groupId: string | null,
  companyId: string | null,
  limit = 1000,
) {
  if (!companyId || Number.isNaN(Number(companyId)) || !groupId) {
    return [];
  }
  try {
    const { data } = await api.post<Product[]>('/produto/listarPorGrupo', {
      IdEmpresa: +companyId,
      IdGrupo: groupId,
      Limit: limit,
    });

    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
}
