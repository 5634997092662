import React, { InputHTMLAttributes, Ref, forwardRef } from 'react';
import { InputProp } from './styles';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
  const { type, style, ...rest } = props;
  return (
    <InputProp ref={ref} type={type ?? 'text'} style={style ?? {}} {...rest} />
  );
});

export default Input;
