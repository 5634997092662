import React, { useEffect, useState } from 'react';
import {
  MdOutlineAccountCircle,
  MdMapsHomeWork,
  MdShoppingBasket,
  MdLogout,
} from 'react-icons/md';
import { useConfig } from '../../hooks/config';
import Address from './Address';
import Delivery from './Delivery';
import Profile from './Profile';
import {
  Container,
  ContainerSubTabs,
  HeaderAccount,
  HeaderAccountItem,
  HeaderAccountItemIcon,
  HeaderAccountItemName,
} from './styles';
import { useAuth } from '../../hooks/auth';
import { FloatCartButton } from '../../components/FloatCartButton';
import { useTab } from '../../hooks/tabIndex';

const Account = () => {
  const sizeIcons = 25;
  const { params } = useTab();
  const { config } = useConfig();
  const { signOut, isAuth } = useAuth();
  const [subTab, setSubTab] = useState(1);

  if (!isAuth) {
    return <div />;
  }

  return (
    <Container>
      <HeaderAccount>
        <HeaderAccountItem
          color={config.Tema}
          selected={subTab === 1}
          onClick={() => setSubTab(1)}
        >
          <HeaderAccountItemIcon>
            <MdShoppingBasket size={sizeIcons} />
          </HeaderAccountItemIcon>
          <HeaderAccountItemName>Pedidos</HeaderAccountItemName>
        </HeaderAccountItem>
        <HeaderAccountItem
          color={config.Tema}
          selected={subTab === 2}
          onClick={() => setSubTab(2)}
        >
          <HeaderAccountItemIcon>
            <MdMapsHomeWork size={sizeIcons} />
          </HeaderAccountItemIcon>
          <HeaderAccountItemName>Endereços</HeaderAccountItemName>
        </HeaderAccountItem>
        <HeaderAccountItem
          color={config.Tema}
          selected={subTab === 3}
          onClick={() => setSubTab(3)}
        >
          <HeaderAccountItemIcon>
            <MdOutlineAccountCircle size={sizeIcons} />
          </HeaderAccountItemIcon>
          <HeaderAccountItemName>Conta</HeaderAccountItemName>
        </HeaderAccountItem>
        <HeaderAccountItem
          color={config.Tema}
          onClick={() => {
            signOut();
            window.location.reload();
          }}
        >
          <HeaderAccountItemIcon>
            <MdLogout size={sizeIcons} />
          </HeaderAccountItemIcon>
          <HeaderAccountItemName>Sair</HeaderAccountItemName>
        </HeaderAccountItem>
      </HeaderAccount>
      <ContainerSubTabs>
        <Delivery visible={subTab === 1} orderId={params.orderId} />
        <Address visible={subTab === 2} />
        <Profile visible={subTab === 3} />
      </ContainerSubTabs>
      <FloatCartButton />
    </Container>
  );
};

export default Account;
