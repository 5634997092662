import api from '../../services/api';

export async function getPaymentByOrderId(
  orderId: string,
  companyId: string | number,
): Promise<any | null> {
  try {
    const { data } = await api.get(`pagamento/pedido/${companyId}/${orderId}`);

    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
