import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background: ${({ theme }) => theme.colors.background};
  flex-direction: column;
  overflow: auto;
`;

export const ContainerOrder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

export const ContainerInfoOrder = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  font-weight: 600;
`;

export const DataOrder = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 6px;
  color: ${({ theme }) => theme.colors.text};
`;

export const PaymentOrder = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 6px;
  color: ${({ theme }) => theme.colors.text};
`;

export const TipeOrder = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 6px;
  color: ${({ theme }) => theme.colors.text};
`;

export const ContainerProducts = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
`;

export const Product = styled.div`
  display: flex;
  margin: 5px 0px;
  padding: 6px;
  border-radius: 4px;
  flex-direction: column;
  font-weight: 600;
`;

export const DescriptionProduct = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Borda = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
`;

export const BordaDescription = styled.span`
  font-size: 10px;
  font-weight: 700;
`;

export const LabelProduct = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main};
`;

export const ValueProduct = styled.div`
  font-size: 10px;
  font-weight: normal;
  margin: 0px 4px;
  color: ${({ theme }) => theme.colors.text};
`;

export const UnityProduct = styled.div`
  display: flex;
`;

export const SizeProduct = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.text};
`;

export const MountingsProduct = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MountingProductTitle = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.main};
`;

export const MountingDescription = styled.div`
  display: flex;
  gap: 4px;
  font-size: 10px;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.text};
`;

export const FlavorProductContainer = styled.div`
  display: flex;
`;

export const AdditionalsProductContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlavorProduct = styled.div`
  display: flex;
  align-items: center;
`;

export const ObservationProductContainer = styled.div`
  display: flex;
`;

export const PriceProduct = styled.div`
  display: flex;
`;

export const TotalProduct = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${(props) => props.color ?? '#000'};
`;

export const ContainerAddress = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
`;

export const Address = styled.div`
  font-size: 12px;
  padding: 2px;
  color: ${({ theme }) => theme.colors.text};
`;

export const ContainerTotal = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const Total = styled.div`
  padding: 2px;
`;

export const SubTotalOrder = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text};
`;

export const TotalDelivery = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
`;

export const TotalOrder = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text};
`;

export const StatusOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  justify-content: center;
`;

export const StatusOrder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text};
`;

export const Status = styled.div<{ status: boolean }>`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ status, theme }) =>
    status ? theme.colors.success : theme.colors.shapeGray};
  height: 50px;
  width: 50px;
  margin: 10px;
  font-size: 30px;
`;
