import styled from "styled-components";

type Props = {
    size: number
}

export const Container = styled.div<Props>`
    border-radius: 10px;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

export const Content = styled.div<Props>`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    transform: rotate(-30deg) scale(1.2);
    gap: ${({ size }) => size / 12}px;
`;
