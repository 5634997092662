import { useState } from 'react';
import { Box } from '../../../../components/Box/Box';
import { Text } from '../../../../components/Text/Text';
import { GoalCardProps } from './types';
import { useTheme } from 'styled-components';
import { Button } from './styles';
import { moeda } from '../../../../utils/functions';
import { Spacer } from '../../../../components/Spacer';

export function GoalCard({
  setUseGoal: propSetUseGoal,
  goal,
  total,
}: GoalCardProps) {
  const [useGoal, setUseGoal] = useState(false);
  const { colors } = useTheme();

  const handleUseGoal = () => {
    const newValue = !useGoal;
    setUseGoal(newValue);
    propSetUseGoal?.(newValue);
  };

  const handlePrize = () => {
    if (goal?.Tipo === 'amount') {
      return moeda(goal?.Valor);
    }

    if (goal?.Tipo === 'delivery') {
      return 'Frete grátis';
    }

    if (goal?.Tipo === 'percentage') {
      return `Desconto de ${goal.Porcentagem}%`;
    }
  };

  return (
    <Box bgColor={colors.shapeDark} padding={8} radius={12} my={8}>
      <Box column width={'100%'}>
        <Text variant="info" color={colors.shape}>
          Resgatar prêmio
        </Text>

        <Text variant="text" color={colors.shape}>
          Prêmio {handlePrize()}
        </Text>

        <Box>
          <Text
            variant="info"
            color={colors.shapeGray}
            style={{ textDecoration: 'line-through' }}
          >
            {moeda(total)}
          </Text>
          <Spacer x={8} />

          <Text variant="info" color={colors.success}>
            {moeda(
              total - (goal?.Valor ?? 0) > 0 ? total - (goal?.Valor ?? 0) : 0,
            )}
          </Text>
        </Box>

        <Spacer y={8} />

        <Button
          onClick={handleUseGoal}
          variant="outlined"
          color={useGoal ? colors.main : colors.shape}
        >
          {useGoal ? 'Ativado' : 'Ativar'}
        </Button>
      </Box>
    </Box>
  );
}
