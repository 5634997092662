import api from '../../services/api';

export async function requestForgotPassword(email: string): Promise<boolean> {
  try {
    await api.post('/usuario/recovery-pass', { email });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function sendConfirmationCode(
  code: string,
  email: string,
): Promise<boolean> {
  try {
    const { data } = await api.post('usuario/confimation-recovery', {
      code,
      email,
    });

    return data?.valid;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function sendNewPassword(
  pass: string,
  email: string,
  confimationCode: string,
) {
  try {
    await api.post('/usuario/update-pass', {
      pass,
      email,
      code: confimationCode,
    });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
}
