import { ProductSize } from '../../../entities/ProductSize.entity';
import { Product } from '../../../entities/Product.entity';
import { descontoPercentual } from '../../../utils/functions';
import { PizzaSizeLabel } from '../components/PizzaSizeLabel/PizzaSizeLabel';

export const parseAndMapSizes = (sizes: ProductSize[], product: Product) => {
  function mapSizes(size: ProductSize) {
    let price = size.Preco;

    if (product?.HasDesconto) {
      price = size.PrecoDesconto;
    }

    if (product?.HasDescontoPercentual) {
      price = descontoPercentual(size.Preco, product.DescontoPercentual);
    }

    if (!(price > 0)) {
      return;
    }

    return {
      key: size.Id,
      label: <PizzaSizeLabel size={size} price={price} />,
      value: size.Descricao,
      price: size.Preco,
      discountPrice: size.PrecoDesconto,
      hasDiscount: product?.HasDesconto,
      hasPercentDiscount: product?.HasDescontoPercentual,
      percentDiscount: product?.DescontoPercentual,
      visible: true,
    };
  }

  const mappedSizes = sizes?.map(mapSizes)?.filter(Boolean);

  return mappedSizes;
};
