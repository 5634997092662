import { useCallback, useState } from 'react';
import { RiCouponFill } from 'react-icons/ri';
import { useCart } from '../../../hooks/useCart';
import api, { IdEmpresa } from '../../../services/api';
import { notification } from '../../../utils/notifications';
import {
  ButtonBackContainer,
  Container,
  CoupomContainer,
  CoupomOptionsContainer,
} from './styles';
import * as yup from 'yup';
import Input from '../../../components/Input';
import { FinishButton, FinishTitle } from '../styles';
import { BackButton } from '../../../components/ButtonBack';
import { useTheme } from 'styled-components';

export const schemaCoupom = yup.object().shape({
  IdEmpresa: yup.number().required('Código da loja obrigatório'),
  CodigoPromo: yup.string().required('Código promocional obrigatório'),
  Total: yup.string().required('Total do carrinho obrigatória'),
});

const CoupomInsert = ({ show, close, setCoupom }) => {
  const [promotionalCode, setPromotionalCode] = useState(null);
  const { total } = useCart();
  const { colors } = useTheme();

  const handleSearch = useCallback(async () => {
    const data = {
      IdEmpresa: IdEmpresa,
      CodigoPromo: promotionalCode,
      Total: total,
    };

    schemaCoupom
      .validate(data)
      .then(async (validated) => {
        await api.post('/cupom/encontrarCupom', validated).then((res) => {
          if (res.data.Cod === 0) {
            setCoupom(res.data.Msg);
            close();
          } else {
            notification('info', res.data.Msg);
          }
        });
      })
      .catch((err) => {
        notification('warn', err.errors[0] ?? 'Erro ao pesquisar cupom');
      });
  }, [promotionalCode]);

  return (
    <Container>
      <ButtonBackContainer>
        <BackButton onClick={close} />
      </ButtonBackContainer>
      <CoupomContainer open={show}>
        <FinishTitle>
          <RiCouponFill color={colors.success} size={50} />
          Cupom de desconto
        </FinishTitle>
        <Input
          name="promotional_code"
          value={promotionalCode}
          onChange={(e) => setPromotionalCode(e.target.value)}
          placeholder="Informe o código de desconto"
        />
        <CoupomOptionsContainer>
          <FinishButton onClick={close}>Cancelar</FinishButton>
          <FinishButton onClick={handleSearch} mainColor>
            Confirmar
          </FinishButton>
        </CoupomOptionsContainer>
      </CoupomContainer>
    </Container>
  );
};

export default CoupomInsert;
