import React from 'react';
import { Container, Loader } from './style';
import { useTheme } from 'styled-components';

const Loading = ({ loading = true }) => {
  const { colors } = useTheme();
  return <Container>{loading && <Loader color={colors.main} />}</Container>;
};

export default Loading;
