export const screens = {
  home: 1,
  search_product: 2,
  cart: 3,
  login: 4,
  info: 5,
  create_account: 6,
  consult_order: 7,
  account: 8,
  forgot_password: 9,
} as const;
