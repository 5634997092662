import { useEffect, useMemo, useState } from 'react';
import { useGroup } from '../../../../hooks/group';
import { useProduct } from '../../../../hooks/useProduct';
import api from '../../../../services/api';
import {
  getPriceSmaller,
  priceSmallerSizeView,
} from '../../../../utils/functions-products';
import { getSizesByProduct } from '../../../../httpRequests/sizes/getSizesByProduct';

export const useProductCard = ({ groupSizes, group, product, promotion }) => {
  const { setProduct } = useProduct();
  const { setGroup, setPreviewsPageHome } = useGroup();
  const baseUrl = api.getUri();
  const [sizes, setSizes] = useState(groupSizes);

  const priceDiscount = product.ValorDesconto;
  const price = product.PrecoVenda;

  const productSizes = useMemo(() => {
    return sizes?.filter((size) => size.IdProduto === product.Id);
  }, [sizes]);

  const { smallPrice, withoutDiscount: originalPrice } = priceSmallerSizeView(
    product,
    productSizes,
  );

  const {
    smaller: smallerPizzaPrice,
    withoutDiscount: smallerPizzaWithoutDiscount,
  } = getPriceSmaller(productSizes, product);

  const hasProductSizes = productSizes.length > 0;

  const handleClick = (_product) => {
    setProduct(_product);
    setGroup(group);
    setPreviewsPageHome(true);
  };

  useEffect(() => {
    if (promotion && product.IsPizza) {
      getSizesByProduct(product.Id).then((data) => {
        setSizes(data);
      });
    }
  }, [promotion, product]);

  return {
    baseUrl,
    priceDiscount,
    price,
    smallPrice,
    originalPrice,
    smallerPizzaPrice,
    smallerPizzaWithoutDiscount,
    hasDiscount: !!product.HasDesconto,
    hasPercentualDiscount: !!product.HasDescontoPercentual,
    percentualDiscount: product.DescontoPercentual,
    hasProductSizes,
    handleClick,
  };
};
