export function mapStateDecrement(product, mounting) {
  return (item) => {
    if (product.id !== item.id) return item;

    const quantity = item.quantidade - parseInt(mounting?.Incrementar || 1);

    return {
      ...item,
      quantidade: quantity >= 0 ? quantity : 0,
    };
  };
}

export function mapStateIncrement(product, mounting) {
  return (item) => {
    if (product.id !== item.id) return item;

    let quantity = item.quantidade || 1;

    if (product.addMaisItens) {
      quantity = item.quantidade + parseInt(mounting.Incrementar);
    }

    return { ...item, quantidade: quantity };
  };
}
