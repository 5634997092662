import { useTheme } from 'styled-components';
import ReactSelect from 'react-select';

type SelectProps<Option> = {
  closeMenuOnSelect?: boolean;
  options: Option[];
  value: Option;
  onChange: (newValue: any) => void;
  placeholder?: string;
  isClearable?: boolean;
};

export function Select<Option>(props: SelectProps<Option>) {
  const {
    closeMenuOnSelect = true,
    options,
    value,
    onChange,
    placeholder,
    isClearable = true,
  } = props;
  const { colors } = useTheme();

  return (
    <ReactSelect
      closeMenuOnSelect={closeMenuOnSelect}
      styles={{
        control: (styles) => ({
          ...styles,
          backgroundColor: colors.backgroundSecondary,
          borderWidth: '1px',
          borderColor: colors.main,
          borderRadius: '10px',
          boxShadow: 'none',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? colors.main
            : colors.backgroundSecondary,
          color: colors.text,
          '&:hover': {
            backgroundColor: state.isSelected
              ? colors.main
              : colors.backgroundSecondary,
          },
        }),
        input: (styles) => ({ ...styles, color: colors.text }),
        placeholder: (styles) => ({
          ...styles,
          fontSize: '14px',
          color: colors.textSecondary,
          fontWeight: 500,
        }),
        singleValue: (styles) => ({ ...styles, color: colors.text }),
      }}
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isClearable={isClearable}
    />
  );
}
