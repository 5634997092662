import { AxiosError } from 'axios';
import api from 'services/api';
import { notifications } from '../../utils/notifications';

type SendOrder = {
  Cod: number;
  Msg: string;
  delivery_id: string;
  payment: {
    type: string;
    Charge: any;
  };
};

export async function sendOrder(formData: FormData) {
  try {
    const response = await api.post<SendOrder>('/pedido/criar', formData);

    if (response?.data) {
      return response.data;
    }
  } catch (error) {
    let errorMessage = 'Não foi possivel fazer o pedido.';

    if (error instanceof AxiosError) {
      const responseMessage = error?.response?.data?.Msg;
      if (responseMessage) errorMessage = responseMessage;
    }

    notifications.error(errorMessage);
  }
}
