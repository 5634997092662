import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: ${(props) => props.color ?? '#fff'};
  background-image: ${({ backgroundImage }) => backgroundImage ? `url("${backgroundImage}")` : 'none'};
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: row;
`;

export const HeaderImage = styled.img`
  background-color: #fff;
  margin: 15px;
  border-radius: 10px;
  max-width: 100px;
  height: 90px;
`;

export const HeaderItem = styled.div`
  display: flex;
  flex: 2;
  padding: 10px;
  flex-direction: column;
  color: ${(props) => (props.color ? '#fff' : '#000')};
  justify-content: center;
`;

export const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const HeaderDescription = styled.p`
  margin: 0;
  margin-top: 5px;
  font-size: 11px;
`;

export const FreeShippingContainer = styled.div`
  font-size: 11px;
  font-weight: 500;
`;

export const FreeShipping = styled.div``;

export const AvailabilityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color ?? ''};
  width: 70px;
  padding: 2px;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
`;
