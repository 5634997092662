import { Spacer } from '../../../../components/Spacer';
import { FallbackImage } from '../../../../components/FallbackImage';
import CheckBox from '../../../../components/CheckBox';
import { moeda } from '../../../../utils/functions';
import api from '../../../../services/api';
import { FlavorProps } from './types';
import {
  ItemDescription,
  ItemDescriptionWrapper,
  ItemImage,
  ItemImageWrapper,
  ItemLabel,
  ItemPrice,
  ItemPriceWrapper,
  Items,
} from './styles';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Text } from 'components/Text/Text';

const Border = ({ border, index, checkAdd, selectedSize }: FlavorProps) => {
  const baseUrl = api.getUri();
  const theme = useTheme();

  const sizePrices = useMemo(() => {
    const borderSizePrice = border?.tamanhos?.find(
      (t: any) => t.IdTamanho === selectedSize?.IdTamanho,
    );

    return {
      price: borderSizePrice?.Preco,
      discountPrice: borderSizePrice?.PrecoDesconto,
    };
  }, [border, selectedSize]);

  return (
    <Items key={border.id} onClick={() => checkAdd(index, border)}>
      <ItemImageWrapper>
        {border?.image ? (
          <ItemImage
            src={`${baseUrl}${border.image}`}
            alt={border?.label ?? ''}
          />
        ) : (
          <FallbackImage size={60} />
        )}
      </ItemImageWrapper>

      <ItemDescriptionWrapper>
        <ItemLabel>{border.label}</ItemLabel>
        <ItemDescription>{border.composicao}</ItemDescription>
      </ItemDescriptionWrapper>

      {Number(sizePrices.discountPrice) > 0 ? (
        <>
          {Number(sizePrices?.price) ? (
            <ItemPriceWrapper>
              <Text
                color={theme.colors.shapeGray}
                variant={'info'}
                size="xxs"
                style={{ textDecoration: 'line-through' }}
              >
                {moeda(sizePrices.price)}
              </Text>
            </ItemPriceWrapper>
          ) : null}
          <Spacer x={4} />
          {Number(sizePrices?.discountPrice) > 0 ? (
            <ItemPriceWrapper>
              <ItemPrice color={theme.colors.success}>
                {moeda(sizePrices.discountPrice)}
              </ItemPrice>
            </ItemPriceWrapper>
          ) : null}
        </>
      ) : (
        <>
          {Number(sizePrices?.price) ? (
            <ItemPriceWrapper>
              <ItemPrice>{moeda(sizePrices.price)}</ItemPrice>
            </ItemPriceWrapper>
          ) : null}
        </>
      )}

      <Spacer x={10} />
      <CheckBox
        checked={border?.check ?? false}
        placeholder={undefined}
        value={undefined}
        onChange={undefined}
        disabled={undefined}
      />
    </Items>
  );
}

export default Border