import api, { IdEmpresa } from '../../services/api';

export async function getCompany(companyId: number) {
  try {
    if (!companyId) {
      return null;
    }

    const { data } = await api.get(`/empresa/encontrar/${companyId}`);

    return data;
  } catch (error) {
    return null;
  }
}
