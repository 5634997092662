import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    width: 100px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    align-self: center;
    margin: 10px;
`;

export const Prev = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

export const Page = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.text};
`;

export const Next = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;
