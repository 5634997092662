import { createContext, useContext, useRef, useState } from 'react';

const TabContext = createContext({
  tab: 1,
  setTab: (tab) => {},
  goBack: () => {},
  reset: () => {},
  handleFinish: (value = true) => {},
  finish: false,
  params: {},
});

export const TabProvider = ({ children }) => {
  const [tab, setTab] = useState(1);
  const previews = useRef([tab]);
  const [finish, setFinish] = useState(false);
  const [params, setParams] = useState({});

  const changeTab = (newTab, routeParams = {}) => {
    previews.current.push(tab);
    setTab(newTab);
    setParams(routeParams);
  };

  const goBack = () => {
    if (previews.current.length > 0) {
      setTab(previews.current.at(-1));
      previews.current.pop();
    }
  };

  const reset = () => {
    setTab(1);
    previews.current = [1];
  };

  const handleFinish = (value) => {
    setFinish(value);
  };

  return (
    <TabContext.Provider
      value={{
        tab,
        params,
        finish,
        setTab: changeTab,
        goBack,
        reset,
        handleFinish,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

export function useTab() {
  const context = useContext(TabContext);

  if (!context) {
    throw new Error('useTab must be used within an TabContext');
  }
  return context;
}
