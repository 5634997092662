import { GroupSchedules } from '../../entities/group-schedules.entity';
import api from '../../services/api';

const getGroupsShedules = async (companyId: string | null) => {
  if (!companyId || Number.isNaN(Number(companyId))) {
    return [];
  }
  try {
    const { data } = await api.get<GroupSchedules[]>(
      `/horarios-grupo/listar/${companyId}`,
    );

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export { getGroupsShedules };
