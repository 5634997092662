import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, FormProfile } from './styles';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { mask, unMask } from 'remask';
import Label from '../../../components/Label';
import Title from '../../../components/Title';
import { useConfig } from '../../../hooks/config';
import { useAuth } from '../../../hooks/auth';
import { getUser } from '../../../httpRequests/user/getUser';
import { updateUser } from '../../../httpRequests/user/updateUser';
import { Spacer } from '../../../components/Spacer';

type FormState = {
  document: string;
  phone: string;
  email: string;
  name: string;
};

const defaultFormState: FormState = {
  document: '',
  phone: '',
  email: '',
  name: '',
};

const Profile = ({ visible }: { visible?: boolean }) => {
  const [form, setForm] = useState<FormState>(defaultFormState);
  const profileUpdatedRef = useRef<boolean>(false);
  const { config } = useConfig();
  const { user } = useAuth();

  const onChange = (
    field: keyof typeof form,
  ): ((e: React.ChangeEvent<HTMLInputElement> | string) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement> | string): void => {
      const newValue = typeof e === 'string' ? e : e.target.value;

      setForm((state) => ({
        ...state,
        [field]: newValue,
      }));
    };
  };

  const update = useCallback(async (): Promise<void> => {
    const data = await getUser(user.Id);
    if (data) {
      setForm({
        name: data.Nome,
        document: mask(data.Document, ['999.999.999-99']),
        phone: mask(data.Telefone, ['(99)99999-9999']),
        email: data.Email,
      });
      profileUpdatedRef.current = true;
    }
  }, [user]);

  const saveChanges = useCallback(() => {
    updateUser(user.Id, {
      Nome: form.name,
      Telefone: form.phone,
      Email: form.email,
      Document: form.document?.replace(/[.-]/g, ''),
    });
  }, [form]);

  useEffect(() => {
    if (!profileUpdatedRef.current) {
      update();
    }
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <Container>
      <Title>Minha conta</Title>
      <FormProfile>
        <Label text="Nome" styles={{ marginLeft: '15px' }} />
        <Input
          placeholder="Nome"
          value={form.name}
          onChange={onChange('name')}
          color={config?.Tema}
        />

        <Label text="CPF" styles={{ marginLeft: '15px' }} />
        <Input
          placeholder="CPF"
          value={form.document}
          onChange={(e) =>
            onChange('document')(
              mask(unMask(e.target.value), ['999.999.999-99']),
            )
          }
          color={config?.Tema}
        />

        <Label text="Celular" styles={{ marginLeft: '15px' }} />
        <Input
          disabled={false}
          placeholder="Celular"
          value={form.phone}
          onChange={(e) =>
            onChange('phone')(mask(unMask(e.target.value), ['(99)99999-9999']))
          }
          color={config?.Tema}
        />
        <Label text="E-mail" styles={{ marginLeft: '15px' }} />
        <Input
          placeholder="E-mail"
          value={form.email}
          onChange={onChange('email')}
          color={config?.Tema}
        />

        <Spacer y={24} />

        <Button onClick={saveChanges} fullScreen>
          Salvar
        </Button>
      </FormProfile>
    </Container>
  );
};

export default Profile;
