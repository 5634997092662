import React from 'react';
import * as S from './styles';
import { useMountingsInsert } from './useMountingsInsert';
import { MountItem } from '../MountItem';

const MountingsInsert = ({
  mountings,
  checkMountings,
  setCheckMountings,
  mountingsRefs,
  additionalRef,
}) => {
  const { addMounting, remMounting, handleImage, handleIsDisabled } =
    useMountingsInsert({ setCheckMountings, checkMountings });

  return (
    <S.ContainerMountings>
      {mountings.map((mount, index) => (
        <MountItem
          key={mount.DescricaoMontagem}
          index={index}
          mount={mount}
          mountingsRefs={mountingsRefs}
          addMounting={addMounting}
          remMounting={remMounting}
          handleImage={handleImage}
          handleIsDisabled={handleIsDisabled}
          checkMountings={checkMountings}
          additionalRef={additionalRef}
        />
      ))}
    </S.ContainerMountings>
  );
};

export default MountingsInsert;
