import React from 'react';
import { MdPhone, MdMail } from 'react-icons/md';
import {
  FaWhatsapp,
  FaGlobeAmericas,
  FaFacebookSquare,
  FaInstagram,
} from 'react-icons/fa';
import { useCompany } from '../../hooks/useCompany';
import { Container, ContainerText, TextName, TextValue } from './styles';
import { redirecionarWhats } from '../../utils/functions';
import { useTheme } from 'styled-components';

const Info = () => {
  const { company } = useCompany();
  const { colors } = useTheme();
  const { width } = window.screen;

  return (
    <Container>
      {company.Telefone && (
        <ContainerText>
          <TextName>
            <MdPhone size={width > 820 ? 50 : 30} color={colors.main} />
          </TextName>
          <TextValue href={'tel:' + company.Telefone} sizeProp={width > 820}>
            {company.Telefone}
          </TextValue>
        </ContainerText>
      )}
      {company.Celular && (
        <ContainerText>
          <TextName>
            <FaWhatsapp size={width > 820 ? 50 : 30} color={colors.main} />
          </TextName>
          <TextValue
            onClick={() => redirecionarWhats(company.Celular)}
            sizeProp={width > 820}
          >
            {company.Celular}
          </TextValue>
        </ContainerText>
      )}
      {company.Email && (
        <ContainerText>
          <TextName>
            <MdMail size={width > 820 ? 50 : 30} color={colors.main} />
          </TextName>
          <TextValue href={'mailto:' + company.Email} sizeProp={width > 820}>
            {company.Email}
          </TextValue>
        </ContainerText>
      )}
      {company.Site && (
        <ContainerText>
          <TextName>
            <FaGlobeAmericas size={width > 820 ? 50 : 30} color={colors.main} />
          </TextName>
          <TextValue
            href={'https://' + company.Site}
            sizeProp={width > 820}
            target="_blank"
          >
            {company.Email}
          </TextValue>
        </ContainerText>
      )}
      {company.Facebook && (
        <ContainerText>
          <TextName>
            <FaFacebookSquare
              size={width > 820 ? 50 : 30}
              color={colors.main}
            />
          </TextName>
          <TextValue
            href={'https://facebook.com/' + company.Facebook}
            target="_blank"
            sizeProp={width > 820}
          >
            {company.Facebook}
          </TextValue>
        </ContainerText>
      )}
      {company.Instagram && (
        <ContainerText>
          <TextName>
            <FaInstagram size={width > 820 ? 50 : 30} color={colors.main} />
          </TextName>
          <TextValue
            href={'https://instagram.com/' + company.Instagram}
            target="_blank"
            sizeProp={width > 820}
          >
            {company.Instagram}
          </TextValue>
        </ContainerText>
      )}
    </Container>
  );
};

export default Info;
