import { shade } from 'polished';
import styled from 'styled-components';

const statusColors = {
  CONCLUIDO: '#439a00',
  ENTREGA: '#007bff',
  ANDAMENTO: '#0dcaf0',
  PENDENTE: '#ffc107',
  default: '#dc3545',
};

export const ContainerDeliveries = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 60px;
`;

export const ContainerDelivery = styled.div`
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  margin: 5px 10px;
  border-radius: 8px;
  padding: 8px;
  font-size: 13px;
  cursor: pointer;

  &:active {
    background: ${({ theme }) => shade(0.2, theme.colors.backgroundSecondary)};
    transition: 0.1s;
  }
`;

export const NumberDelivery = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.title};
`;

export const ContainerDataDelivery = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DataDelivery = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  gap: 4px;
`;

export const HourDelivery = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  gap: 4px;
`;

export const ContainerInfoDelivery = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StatusDelivery = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ status }) => statusColors[status] ?? statusColors.default};
  font-weight: 500;
`;

export const PaymentDelivery = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.text};
`;

export const ContainerPriceDelivery = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.text};
`;
