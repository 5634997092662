import api from '../../services/api';
import { Group } from '../../entities/group.entity';

export const getGroups = async (companyId: string | null) => {
  try {
    if (!companyId || Number.isNaN(Number(companyId))) {
      return [];
    }

    const { data } = await api.get<Group[]>(
      `/grupo-produto/listar/${companyId}`,
    );

    if (!Array.isArray(data)) {
      return [];
    }

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
