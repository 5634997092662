import * as yup from 'yup';

export const schemaNotAccount = yup.object().shape({
  company_id: yup.number().required('Código da loja obrigatório'),
  name: yup.string().required('Nome obrigatório'),
  phone: yup
    .string()
    .required('Celular obrigatório')
    .min(14, 'Celular incompleto'),
  type_delivery: yup.string().required('Tipo de entrega obrigatório'),
  payment: yup.string().required('Forma de pagamento obrigatória'),
  address: yup
    .string()
    .when(['type_delivery'], {
      is: (type_delivery) => {
        if (['EN', 'AG'].includes(type_delivery)) {
          return true;
        }
        if (['RE', 'AGRE'].includes(type_delivery)) {
          return false;
        }
      },
      then: (schema) => schema.required('Endereço é obrigatório'),
    })
    .nullable(),
  neighborhood: yup
    .string()
    .when(['type_delivery'], {
      is: (type_delivery) => ['EN', 'AG'].includes(type_delivery),
      then: (schema) => schema.required('Bairro é obrigatório'),
    })
    .nullable(),
  schedule_date: yup
    .date()
    .when(['type_delivery'], {
      is: (type_delivery) => ['AGRE', 'AG'].includes(type_delivery),
      then: (schema) => schema.required('Data para agendamento é obrigatório'),
    })
    .nullable(),
});

export const schemaAccount = yup.object().shape({
  company_id: yup.number().required('Código da loja obrigatório'),
  type_delivery: yup.string().required('Tipo de entrega obrigatório'),
  payment: yup.string().required('Forma de pagamento obrigatória'),
  client_id: yup.string().required('Id de usuário obrigatório'),
  isNewAddress: yup.boolean(),
  address: yup
    .string()
    .when(['type_delivery', 'isNewAddress'], {
      is: (type_delivery, isNewAddress) =>
        ['EN', 'AG'].includes(type_delivery) && isNewAddress,
      then: (schema) => schema.required('Endereço é obrigatório'),
    })
    .nullable(),
  address_id: yup
    .string()
    .when(['type_delivery', 'isNewAddress'], {
      is: (type_delivery, isNewAddress) =>
        ['EN', 'AG'].includes(type_delivery) && !isNewAddress,
      then: (schema) => schema.required('Endereço é obrigatório'),
    })
    .nullable(),
  schedule_date: yup
    .date()
    .when(['type_delivery'], {
      is: (type_delivery) => ['AGRE', 'AG'].includes(type_delivery),
      then: (schema) => schema.required('Data para agendamento é obrigatório'),
    })
    .nullable(),
});
