import api from '../../services/api';

export async function getConfig(companyId: number) {
  try {
    const res = await api.get('/configuracao/encontrar/' + companyId);
    let data = { ...res.data };

    if (!res.data || Object.values(res.data).length === 0) {
      return null;
    }

    Object.entries(data).forEach(([key, value]) => {
      if (value === 'null') {
        data[key] = null;
      }
    });

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}
