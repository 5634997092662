import { shade } from 'polished';
import styled from 'styled-components';

export type ButtonVariants = 'primary' | 'ghost' | 'outlined';

const variants = {
  background: {
    primary: 'main',
    ghost: 'transparent',
    outlined: 'transparent',
  },
  color: {
    primary: 'shape',
    ghost: 'main',
    outlined: 'main',
  },
  borderColor: {
    primary: 'transparent',
    ghost: 'transparent',
    outlined: 'main',
  },
} as const;

export const Container = styled.button<{
  variant: ButtonVariants;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, variant }) =>
    theme.colors[variants.background[variant]]};
  color: ${({ theme, variant, color }) =>
    color ?? theme.colors[variants.color[variant]]};
  padding: 8px 12px;
  width: 100%;
  max-width: 560px;
  border-radius: 10px;
  border: 2px solid;
  border-color: ${({ theme, variant, color }) =>
    variant !== 'outlined'
      ? 'transparent'
      : color ?? theme.colors[variants.borderColor[variant]]};
  font-size: 16px;
  font-weight: 600;

  :disabled {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.5;
    transition: 0.2s;
  }
`;
