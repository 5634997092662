import { Container } from './styles';
import { CrossSelling } from '../CrossSelling';
import { SubmitButtom } from '../SubmitButton';

type Props = {
  onSubmit: () => void;
  total: number;
};

export function CartFooter({ total, onSubmit }: Props) {
  return (
    <Container>
      <CrossSelling />
      <SubmitButtom onSubmit={onSubmit} total={total} />
    </Container>
  );
}
