import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background};
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  box-shadow: -1px 1px 5px -4px #000;
`;

export const Header = styled.div`
  display: flex;
  position: relative;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 60px;
  height: 60px;
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 6px;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.h4`
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.text};
`;

export const HeaderText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyItem = styled.div`
  margin-top: 10px;
`;

export const BodyItemTitle = styled.h6`
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.text};
  text-transform: capitalize;
`;

export const BodyText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
`;

export const BodyItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 4px;
  margin-top: 4px;
`;

export const BodyItemImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 6px;
  box-shadow: -1px 1px 5px -4px #000;
  overflow: hidden;
`

export const BodyItemImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const TotalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const TotalValue = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #28a745;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
`;

export const Amount = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
`;

export const SpacerWithBorder = styled.div`
  display: flex;
  flex: 1;
  width: 1px;
  height: 8px;
  border-bottom: 1px dashed lightgrey;
`;
