import React from 'react';
import { Container } from './styles';
import { ButtonProps } from './types';

export function Button({
  children,
  variant = 'primary',
  ...rest
}: ButtonProps) {
  return (
    <Container variant={variant} {...rest}>
      {children}
    </Container>
  );
}

export default Button;
