import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const regex = /^\/(\d+)$/;
const match = window.location.pathname.match(regex);
export const IdEmpresa = match?.[1] || null;

export default api;
