import styled from 'styled-components';

export const CompaniesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  color: #424242;
`;

export const ListCompanies = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 0.5rem;
  overflow: auto;

  @media screen and (min-width: 426px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const Company = styled.div`
  flex-grow: 1;
  padding: 0.5rem;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); */
  border-radius: 0.25rem;
  display: flex;
  background-color: #fff;
  cursor: pointer;
`;

export const CompanyLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 0.25rem;
`;

export const CompanyInfo = styled.div`
  padding: 2px 5px;
`;

export const CompanyTitle = styled.div`
  font-size: 0.875rem;
  line-height: 1.3;
  color: ${(props) => props.color ?? '#212529'};
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`;

export const CompanyDescription = styled.div`
  font-size: 0.82rem;
  font-weight: 500;
  color: #969cb2;
  height: 2.1rem;
  position: relative;
  overflow-y: hidden;
  margin-bottom: 0.25rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  &::after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 1.2em;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 50%
    );
  }
`;

export const CompanyContacts = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Contact = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 500;
  margin: 0px 4px;
`;
