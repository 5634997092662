export const useCartItem = ({ item }) => {
    const groupMontagem = (_montagens = []) => {
        const montagens_by_group = {};
        _montagens.forEach((_montagem) => {
            if (Array.isArray(montagens_by_group[_montagem?.DescricaoMontagem])) {
                montagens_by_group[_montagem?.DescricaoMontagem] = [
                    ...montagens_by_group[_montagem?.DescricaoMontagem],
                    _montagem,
                ]
            } else {
                montagens_by_group[_montagem?.DescricaoMontagem] = [_montagem]
            }
        })

        return Object.entries(montagens_by_group)
    }

    const mounts = groupMontagem(item.montagem)

    const getItemBorderPrice = () => {
      const prices = item.border?.tamanho;

      if (Number(prices?.PrecoDesconto) > 0) {
        return Number(prices?.PrecoDesconto) * item.quantidade;
      }

      return (Number(prices?.Preco) || 0) * item.quantidade;
    };

    const totalPrice = (() => {
        let total = 0;

        const productPrice = item.preco * item.quantidade || 0;

        const borderPrice = getItemBorderPrice();

        let additionalPrice = 0;
        let mountingPrice = 0;

        item?.adicionais?.forEach((additional) => {
            const value = additional.preco * additional.amount;
            if (value) { additionalPrice += value }
        })

        mounts.forEach((current) => {
            const currentMounts = current.at(1);
            currentMounts.forEach((_mount) => {
                const currentMountPrice = _mount.precoMontagem;
                if (currentMountPrice) {
                    mountingPrice += currentMountPrice;
                }
            });
        });

        if (productPrice) { total += productPrice }
        if (borderPrice) { total += borderPrice }
        if (additionalPrice) { total += additionalPrice }
        if (mountingPrice) { total += mountingPrice }

        return total;
    })();

    return {
        mounts,
        totalPrice,
    }
}
