import { zonedTimeToUtc } from 'date-fns-tz';
import { Group } from '../../entities/group.entity';
import { GroupSchedules } from '../../entities/group-schedules.entity';
import { getGroupsShedules } from 'httpRequests/group/getGroupsShedules';

// TODO mudar logica do frontend para o backend

const validateSchedules = (current_schedules: GroupSchedules[]) => {
  return !!current_schedules.find((current_schedule) => {
    const time_zone = 'America/Sao_Paulo';
    const { Start, End } = current_schedule;

    const [hour_start, min_start] = Start.split(':');
    const [hour_end, min_end] = End.split(':');

    const date = new Date();
    const now = zonedTimeToUtc(date, time_zone);

    const start_time = zonedTimeToUtc(date, time_zone);
    start_time.setHours(Number(hour_start), Number(min_start), 0, 0);

    const end_time = zonedTimeToUtc(date, time_zone);
    end_time.setHours(Number(hour_end), Number(min_end), 0, 0);

    return now >= start_time && now < end_time;
  });
};

export async function filterGroupsBySchedules(
  groups: Group[],
  companyId: string | null,
) {
  const current_day = new Date().getDay();
  const schedules = await getGroupsShedules(companyId);

  const current_schedules = schedules.filter(
    (__schedule) => __schedule.Day === current_day,
  );

  const filtered = groups.filter((_group) => {
    if (!_group.UsaHorario) return true;

    const current_schedules_group = current_schedules.filter(
      (__schedule) => __schedule.GroupId === _group.Id,
    );

    return validateSchedules(current_schedules_group);
  });

  return filtered;
}
