import styled from 'styled-components';

export const List = styled.select`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  color: ${({ theme }) => theme.colors.text};
  padding: 10px;
  width: 100%;
  max-width: 560px;
  border: 1px solid;
  border-radius: 10px;
  border-color: ${({ theme }) => theme.colors.main};
  font-size: 16px;
  font-weight: 600;
  outline: none;
`;

export const Item = styled.option`
  font-size: 16px;
  font-weight: 600;
`;
