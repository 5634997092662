import addNotification from 'react-push-notification';

// type:  'warn' | 'success' | 'info' | 'error',
export const notification = (type, message) => {
  const titles = {
    warn: '🟡 Aviso',
    success: '🟢 Sucesso',
    info: '🔵 Informação',
    default: '🔴 Erro',
  };

  addNotification({
    title: titles[type] || titles.default,
    theme: 'light',
    message,
    closeButton: `Fechar`,
    duration: 3000,
  });
};

export const notifications = {
  warn: (message) => notification('warn', message),
  success: (message) => notification('success', message),
  info: (message) => notification('info', message),
  error: (message) => notification('error', message),
};
