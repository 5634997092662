import styled from 'styled-components';

export const ContainerRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

export const InputRadioProp = styled.input`
  position: ${(props) => props.position ?? 'absolute'};
  margin-right: 1rem;
  width: 1.25rem;
  height: 1.25rem;
  accent-color: ${({ theme }) => theme.colors.main};
`;

export const LabelRadioProp = styled.label`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.5rem;
  margin-bottom: 4px;
`;
