function mapData(quantity: number) {
  return function (productM: any, i: number) {
    if (productM.Status !== 'False') {
      return {
        id: i,
        idMontagem: productM.IdMontagem,
        DescricaoMontagem: productM.DescricaoMontagem,
        qtdSemCusto: +productM.QtdSemCusto,
        qtdMin: +productM.QtdMin,
        qtdMax: +productM.QtdMax,
        idProd: productM.IdProduto,
        codigoPDV: +productM.CodigoPDV,
        quantidade: productM.ControlaEmbalagem ? quantity : 0,
        addMaisItens: productM.AddItemMaisVezes !== 'False' ? true : false,
        controlaEmbalagem: !!+productM.ControlaEmbalagem,
        label: productM.Descricao,
        preco: productM.PrecoVenda,
        image: productM.ImagemProduto,
        tipoProduto: productM.TipoProduto,
        check: false,
      };
    }
  };
}

export function parseCheckMountings(data: any[], quantity: number) {
  const parsedData = data?.map(mapData(quantity)).filter(Boolean);

  return parsedData || [];
}
