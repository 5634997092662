import { Meta } from 'entities/Meta';

export function getPrizeValue(
  goal: Meta,
  total: number,
  frete: number,
): number {
  if (goal?.Tipo === 'amount' && goal?.Valor) {
    return goal.Valor;
  }

  if (goal?.Tipo === 'delivery') {
    return frete;
  }

  if (goal?.Tipo === 'percentage' && goal.Porcentagem) {
    return total * (1 - goal.Porcentagem / 100);
  }

  return 0;
}
