import React, { PropsWithChildren, CSSProperties } from 'react';
import { font_size, variants_styles } from './styles';

type Variants = 'title' | 'sub_title' | 'sub_title_1' | 'text' | 'info';
type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type TextProps = {
  variant: Variants;
  size?: Size;
  style?: CSSProperties;
  upper?: boolean;
  color?: CSSProperties['color'];
  fontWeight?: CSSProperties['fontWeight'];
};

export const Text = (props: PropsWithChildren<TextProps>) => {
  const {
    children,
    style,
    color,
    variant = 'text',
    upper,
    size,
    fontWeight,
    ...rest
  } = props;

  const handle_styles = (): CSSProperties => {
    const variant_style = variants_styles[variant] || variants_styles.text;
    const fontSize = size ? font_size[size] : {};

    return {
      ...variant_style,
      ...fontSize,
      ...style,
      color,
      textTransform: upper ? 'uppercase' : undefined,
      fontWeight,
    };
  };

  return (
    <span style={handle_styles()} {...rest}>
      {children}
    </span>
  );
};
