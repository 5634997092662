import React from 'react';
import { RouteProps } from './types';

export const Route = ({ component: Component, ...props }: RouteProps) => {
  const { id, isModal, modalOpen, open, current } = props;

  const inactive = id && current !== id;
  const modalClose = isModal && !open;
  const notModalAndModalOpen = !isModal && modalOpen;

  if (inactive || modalClose || notModalAndModalOpen) {
    return null;
  }

  return <Component />;
};
