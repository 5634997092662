import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 130px - 48px);
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  padding: 0 20px;
  height: 55vh;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.title};
`;

export const ContainerSignUp = styled.div``;

export const TextLink = styled.a`
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  font-weight: 700;
`;

export const DescriptionConsult = styled.div`
  font-size: 12px;
  margin-top: 15px;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
`;
