import * as yup from 'yup';
import { notifications } from '../../../utils/notifications';
import { schemaNotAccount, schemaAccount } from '../schemas/yup.schema';

export async function validateForm(formData: any, userId: string) {
  try {
    if (userId) {
      return await schemaAccount.validate(formData);
    }

    return await schemaNotAccount.validate(formData);
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      notifications.error(error.errors);
    }
  }
}
