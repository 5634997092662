import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { Container } from './styles';

export const BackButton = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Container {...props}>
      <MdArrowBack />
    </Container>
  );
};
