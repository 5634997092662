import { TCartItem } from 'entities/cart-item.entity';

export function mapState({ index, value }: { index: number; value: number }) {
  return (item: TCartItem, i: number) => {
    if (i !== index) return item;
    return { ...item, quantidade: item.quantidade + value || 1 };
  };
}

export function getItemBorderPrice(item: TCartItem) {
  const prices = item.border?.tamanho;

  if (Number(prices?.PrecoDesconto) > 0) {
    return Number(prices?.PrecoDesconto) * item.quantidade;
  }

  return (Number(prices?.Preco) || 0) * item.quantidade;
}

export function getItemAdditionalPrice(item: TCartItem) {
  const total = item.adicionais?.reduce((acc, add) => {
    const totalAdd = add.preco * add.amount ?? 1 * add.quantidade ?? 1;
    return acc + totalAdd;
  }, 0);

  return total || 0;
}

export function getItemMountingPrice(item: TCartItem) {
  const total = item.montagem?.reduce((acc, mont) => {
    if (mont.quantidade > 0) {
      const totalMount =
        (!mont.controlaEmbalagem ? mont.precoMontagem : mont.preco) *
        item.quantidade;

      return acc + totalMount;
    }

    return acc;
  }, 0);

  return total || 0;
}
