import React, { useEffect, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import DetailsOrder from '../../../components/DetailsOrder';
import ListDeliveries from '../../../components/ListDeliveries';
import Loading from '../../../components/Loading';
import Title from '../../../components/Title';
import { ButtonReload, Container, ContainerButtonReload } from './styles';
import { useAuth } from '../../../hooks/auth';
import { getOrder } from '../../../httpRequests/order/getOrder';
import { useOrdersSocket } from '../../../hooks/useOrdersSocket';
import { useDeliveries } from './useDeliveries';
import { Order } from '../../../entities/Order.entity';

const Delivery = ({
  visible,
  orderId,
}: {
  visible: boolean;
  orderId?: string;
}) => {
  const { user } = useAuth();
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [loadedOrder, setLoadedOrder] = useState<Order | null>(null);
  const deliveries = useDeliveries({ user });
  useOrdersSocket({
    onUpdate: deliveries.updateDeliveries,
    userId: user?.Id,
  });

  const delivery =
    loadedOrder ||
    deliveries?.deliveries?.find((item) => item?.Id === selectedOrderId);

  const showOrder = (id: string | null) => {
    setSelectedOrderId(id);
  };

  const loadOrder = async (id: string) => {
    const newOrder = await getOrder(id);

    if (newOrder) {
      setLoadedOrder(newOrder);
    }
  };

  const onCloseOrder = () => {
    setSelectedOrderId(null);
    setLoadedOrder(null);
  };

  useEffect(() => {
    if (orderId) {
      loadOrder(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    deliveries.updateDeliveries();
  }, [deliveries.updateDeliveries]);

  return (
    <>
      {visible && (
        <Container>
          <Title>Meus Pedidos</Title>
          <ContainerButtonReload>
            <ButtonReload onClick={() => deliveries.updateDeliveries()}>
              <MdRefresh size={20} />
            </ButtonReload>
          </ContainerButtonReload>
          {deliveries.loading ? (
            <Loading loading={true} />
          ) : (
            <ListDeliveries
              list={deliveries.deliveries}
              showOrder={showOrder}
              page={deliveries.deliveriesPage}
              handlePrev={deliveries.handlePrev}
              handleNext={deliveries.handleNext}
            />
          )}
          {Boolean(delivery) && (
            <DetailsOrder
              order={delivery}
              onClose={onCloseOrder}
              orderId={orderId}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default Delivery;
