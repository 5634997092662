import styled from 'styled-components';

export const CompaniesProductsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background: #fff;
`;

export const SearchContainer = styled.div`
  margin: 10px;
`;

export const ListProductsCompanies = styled.div`
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 78vh;
  flex-wrap: wrap;
`;

export const ProductCompany = styled.div`
  display: flex;
  background: #fff;
  margin: 5px;
  padding: 4px;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  width: 350px;
  height: 80px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
`;

export const ProductCompanyImage = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 5px;
`;

export const ContainerProductCompanyInfo = styled.div`
  margin: 0px 5px;
`;

export const ProductCompanyDescription = styled.div`
  font-weight: bold;
  font-size: 14px;

  @media screen and (max-width: 426px) {
    font-size: 12px;
  }
`;

export const ProductCompanyComposition = styled.div`
  font-size: 0.57rem;
  font-weight: 500;
  word-break: break-word;
  color: #969cb2;
  height: 0.8rem;
  position: relative;
  overflow-y: hidden;
  margin-bottom: 0.15rem;
`;

export const ProductCompanymessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Message = styled.div`
  color: #c7cad6;
  font-weight: 600;
  text-align: center;
`;
