import React from 'react';
import { useAdditional } from './useAdditional';
import * as S from './styles';
import { moeda } from '../../../../utils/functions';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { FallbackImage } from '../../../../components/FallbackImage';
import CheckBox from '../../../../components/CheckBox';

export const Additional = ({ additionals, setAdditionals, additionalRef }) => {
  const {
    quantitySelected,
    maxQuantity,
    noAdditionals,
    handleImage,
    handleReset,
    handleIncrement,
    handleDecrement,
  } = useAdditional({
    additionals,
    setAdditionals,
  });

  return (
    <S.Container ref={additionalRef}>
      <S.HeaderContainer>
        <S.HeaderTitle>Adicionais:</S.HeaderTitle>

        {!!maxQuantity && (
          <S.HeaderInfo>Max. {maxQuantity} opções</S.HeaderInfo>
        )}
      </S.HeaderContainer>
      <S.Content>
        {additionals.map((add) => (
          <S.CardContainer key={add.id}>
            <S.CardImageWrapper>
              {handleImage(add) ? (
                <S.CardImage src={handleImage(add)} alt={add?.label ?? ''} />
              ) : (
                <FallbackImage size={60} />
              )}
            </S.CardImageWrapper>
            <S.CardContent>
              <S.CardTitle>{add.label}</S.CardTitle>
              <S.CardDescription>{add.composicao}</S.CardDescription>
            </S.CardContent>
            <S.CardActionsWrapper>
              {Number(add?.preco) > 0 && (
                <S.CardPrice>{moeda(add.preco)}</S.CardPrice>
              )}
              <S.CardActions>
                <S.ActionIconWrapper onClick={() => handleDecrement(add)}>
                  <FaMinus color="red" size={20} />
                </S.ActionIconWrapper>
                <S.CardAmount>{add.amount ?? 0}</S.CardAmount>
                <S.ActionIconWrapper
                  onClick={() => handleIncrement(add)}
                  isDisabbled={quantitySelected === maxQuantity}
                >
                  <FaPlus color="green" size={20} />
                </S.ActionIconWrapper>
              </S.CardActions>
            </S.CardActionsWrapper>
          </S.CardContainer>
        ))}

        <S.CardContainer style={{ padding: '8px 20px' }}>
          <S.CardContent onClick={handleReset}>
            <S.CardTitle>Sem adicionais</S.CardTitle>

            <CheckBox
              id="check-no-additionals"
              checked={noAdditionals}
              onChangeValue={handleReset}
            />
          </S.CardContent>
        </S.CardContainer>
      </S.Content>
    </S.Container>
  );
};
