import { Text } from 'components/Text/Text';
import { Product as TProduct } from 'entities/Product.entity';
import { Container, List } from './styles';
import { Group } from 'entities/group.entity';
import { Product } from 'pages/Catalog/components/Product/Product';

import { useProducts } from '../../../../hooks/useProducts';
import { useGroups } from '../../../../hooks/useGroups';
import { useSizes } from '../../../../hooks/useSizes';
import { useCart } from '../../../../hooks/useCart';
import { useMemo } from 'react';

type ListCrossSelling = {
  groupSizes: any[];
  group: Group;
  product: TProduct;
}[];

export function CrossSelling() {
  const { cart } = useCart();
  const { groups } = useGroups();
  const { products } = useProducts();
  const { sizes } = useSizes();

  const list = useMemo((): ListCrossSelling => {
    if (
      !(
        cart &&
        cart.length > 0 &&
        groups.length > 0 &&
        products.length > 0 &&
        sizes.length > 0
      )
    ) {
      return [];
    }

    const cartGroupsIds = new Map<string, string>();
    cart.forEach((item) => {
      if (item.idGrupo) cartGroupsIds.set(item.idGrupo, item.idGrupo);
    });

    const elegibleGroupsIds = new Map<string, string>();
    groups.forEach((item) => {
      if (!cartGroupsIds.get(item.Id)) elegibleGroupsIds.set(item.Id, item.Id);
    });

    const elegibleProductsByGroup = new Map<string, TProduct[]>();
    products.forEach((item) => {
      if (elegibleGroupsIds.get(item.Grupo)) {
        const previewsProducts = elegibleProductsByGroup.get(item.Grupo) || [];
        elegibleProductsByGroup.set(item.Grupo, [...previewsProducts, item]);
      }
    });

    const result: ListCrossSelling = [];

    elegibleProductsByGroup.forEach((item) => {
      const randomIndex = Math.floor(Math.random() * item.length);
      const product = item[randomIndex];
      const group = groups.find((groupItem) => groupItem.Id === product.Grupo)!;
      const groupSizes = sizes.filter((size) => size.IdProduto === product.Id);

      result.push({
        group,
        groupSizes,
        product,
      });
    });

    return result;
  }, [cart, groups, products, sizes]);

  if (!(list.length > 0)) {
    return null;
  }

  return (
    <Container>
      <Text variant="title" size="xs">
        Leve também
      </Text>
      <List>
        {list.map(({ product, group, groupSizes }) => (
          <Product
            key={product.Id}
            product={product}
            groupSizes={groupSizes}
            group={group}
            promotion
          />
        ))}
      </List>
    </Container>
  );
}
