import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { User } from '../../../entities/user.entity';
import api, { IdEmpresa } from '../../../services/api';
import { orderByDateTime } from '../../../utils/orderByDateTime';

export function useDeliveries({ user }: { user: User }) {
  const [deliveries, setDeliveries] = useState<any[]>([]);
  const [countDeliveries, setCountDeliveries] = useState(0);
  const [loading, setLoading] = useState(true);
  const [deliveriesPage, setDeliveriesPage] = useState(1);
  const countByPage = 20;

  const updateDeliveries = useCallback(
    async ({ withLoad = true } = {}) => {
      if (!user.Id || !user?.Telefone || !IdEmpresa) {
        return;
      }

      try {
        if (withLoad) setLoading(true);

        const { data } = await api.get(
          `/pedido/meusPedidos/${user.Id}/${user?.Telefone}/${IdEmpresa}?page=${deliveriesPage}`,
        );

        const [responseDeliveries, count] = data;

        const ordered = orderByDateTime(responseDeliveries);

        setDeliveries(ordered);
        setCountDeliveries(count);
      } catch (error) {
        if (error instanceof AxiosError) {
          return console.log(
            `Erro ao carregar seus pedidos. ${error?.response?.data.message}`,
            error,
          );
        }

        console.log(error);
      } finally {
        if (withLoad) setLoading(false);
      }
    },
    [user],
  );

  const handlePrev = useCallback(() => {
    if (deliveriesPage <= 1) {
      return;
    }
    setDeliveriesPage((prev) => prev - 1);
    updateDeliveries();
  }, [updateDeliveries, deliveriesPage]);

  const handleNext = useCallback(() => {
    if (deliveriesPage + 1 >= Math.round(countDeliveries / countByPage)) {
      return;
    }
    setDeliveriesPage((prev) =>
      prev + 1 >= Math.round(countDeliveries / countByPage) ? prev : prev + 1,
    );
    updateDeliveries();
  }, [countDeliveries, deliveriesPage, updateDeliveries]);

  return {
    deliveries,
    deliveriesPage,
    loading,
    updateDeliveries,
    handlePrev,
    handleNext,
  };
}
