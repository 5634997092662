import { defatultStatusState, mapStatusState } from './constants';

export const getAddress = (order: any) => {
  if (!['EN', 'AG'].includes(order?.TipoEntrega)) return;

  return !+order.IdUser
    ? `${order.EnderecoSemCadastro} | ${order.NomeBairro}`
    : `CEP: ${order.Cep}, ${order.Logradouro}, ${order.Numero}, ${order.Bairro}, ${order.Complemento} ${order.PontoRef} - ${order.Cidade}-${order.Uf}`;
};

export function getStatus(status: string) {
  return mapStatusState.get(status) || defatultStatusState;
}
