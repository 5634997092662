import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  color: ${({ theme }) => theme.colors.title};
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerCategory = styled.div`
  overflow: auto;
  white-space: nowrap;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 10px;
`;

export const Category = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 5px;
  padding: 4px;
  border-radius: 5px;
  width: 100px;
  white-space: pre-wrap;
  cursor: pointer;
  border: 1px solid #88888830;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
`;

export const CategoryImage = styled.img`
  background-color: #fff;
  height: 60px;
  width: 60px;
  border-radius: 10px;
  align-self: center;
`;

export const CategoryTitle = styled.div`
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  width: 92px;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  white-space: nowrap;
`;

export const ContainerGroups = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Group = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;

  &:last-child {
    padding-bottom: 90px;
  }
`;
