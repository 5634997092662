import { moeda } from 'utils/functions';
import { PricePizza, PricePizzaContainer } from '../../styles';
import { ProductSize } from '../../../../entities/ProductSize.entity';

type Props = {
  price: Number;
  size: ProductSize;
};

export function PizzaSizeLabel({ size, price }: Props) {
  return (
    <PricePizzaContainer>
      {size.Descricao}
      <PricePizza>{moeda(price)}</PricePizza>
    </PricePizzaContainer>
  );
}
