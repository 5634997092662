import {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import { IdEmpresa } from '../services/api';
import { Settings } from '../entities/Settings';
import { getConfig } from '../httpRequests/config/getConfig';

const ConfigContext = createContext<{ config: Settings }>({
  config: {
    Tema: '',
  },
});

export const ConfigProvider = ({ children }: PropsWithChildren) => {
  const [config, setConfig] = useState<Settings>({});

  const loadCompany = async () => {
    const contractId = Number(IdEmpresa);

    if (contractId) {
      const data = await getConfig(contractId);

      if (data) {
        setConfig(data);
      }
    }
  };

  useEffect(() => {
    loadCompany();
  }, []);

  return (
    <ConfigContext.Provider value={{ config }}>
      {children}
    </ConfigContext.Provider>
  );
};

export function useConfig() {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('ConfigContext must be used within an ConfigContext');
  }
  return context;
}
