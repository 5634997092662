import styled from 'styled-components';

export const PromotionPrice = styled.span`
  color: #379930;
`;

export const PromotionOldPrice = styled.span`
  text-decoration: line-through;
  opacity: 0.7;
  font-size: 10px;
`;

export const ProductImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Product = styled.div`
  display: inline-block;
  margin: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
`;

export const ProductImage = styled.img`
  height: 110px;
  max-width: 110px;
  border-radius: 10px;
`;

export const ProductName = styled.div`
  font-weight: bold;
  white-space: pre-wrap;
  overflow: hidden;
  text-transform: capitalize;
  width: 110px;
  height: 54px;
  font-size: 12px;
`;

export const ProductPrice = styled.div`
  color: ${({ color, theme }) => color ?? theme.colors.text};
  font-weight: 600;
  font-size: ${(props) => props.fontSize ?? 12}px;
`;
