import { useCallback, useEffect, useMemo, useState } from 'react';
import api, { IdEmpresa } from '../../../services/api';
import Input from '../../../components/Input';
import { debounce } from '../../../utils/functions';
import { FaSearch } from 'react-icons/fa';
import { BackButton } from '../../../components/ButtonBack';
import Loading from '../../../components/Loading';
import {
  CompaniesProductsContainer,
  ContainerProductCompanyInfo,
  ListProductsCompanies,
  Message,
  ProductCompany,
  ProductCompanyComposition,
  ProductCompanyDescription,
  ProductCompanyImage,
  ProductCompanymessage,
  SearchContainer,
} from './styles';
import { FallbackImage } from '../../../components/FallbackImage';

const CompaniesProducts = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [products, setProducts] = useState([]);
  const baseUrl = api.getUri();

  const listProducts = useCallback(async (value) => {
    if (value) {
      await api
        .post('/empresa-catalogo/buscarProdutosEmpresas', {
          IdEmpresa,
          Descricao: value,
        })
        .then((response) => {
          setProducts(response.data.length ? response.data : []);
        })
        .catch((error) => console.log(error));
    } else {
      setProducts([]);
    }
    setLoading(false);
  }, []);

  const onChangeSearch = useMemo(
    () =>
      debounce((value) => {
        setLoading(true);
        listProducts(value);
        setSearch(value);
      }, 1500),
    [],
  );

  useEffect(() => {
    onChangeSearch(search);
  }, [search]);

  return (
    <CompaniesProductsContainer>
      <BackButton onClick={close} />
      <SearchContainer>
        <Input
          fullScreen
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Pesquisar produto..."
        />
      </SearchContainer>
      {loading && <Loading loading={loading} />}
      {!loading && (
        <ListProductsCompanies>
          {products.map((prod) => (
            <ProductCompany
              onClick={() => (window.location.pathname = `/${prod.IdEmpresa}`)}
            >
              {prod.Imagem || prod.Logomarca ? (
                <ProductCompanyImage
                  src={
                    prod.Imagem
                      ? `${baseUrl}${prod.Imagem}`
                      : `${baseUrl}${prod.Logomarca}`
                  }
                />
              ) : (
                <FallbackImage size={80} />
              )}
              <ContainerProductCompanyInfo>
                <ProductCompanyDescription>
                  {prod.Descricao}
                </ProductCompanyDescription>
                {prod.Composicao && (
                  <ProductCompanyComposition>
                    {prod.Composicao}
                  </ProductCompanyComposition>
                )}
              </ContainerProductCompanyInfo>
            </ProductCompany>
          ))}
          {!products.length && (
            <ProductCompanymessage>
              <FaSearch size={35} color="#c7cad6" />
              <Message>
                Informe o nome do produto que deseja pesquisar...
              </Message>
            </ProductCompanymessage>
          )}
        </ListProductsCompanies>
      )}
    </CompaniesProductsContainer>
  );
};

export default CompaniesProducts;
