import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background};
`;

export const ContainerSearch = styled.div`
  display: flex;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  margin: 10px;
  width: 100%;
`;

export const InputSearch = styled.input`
  flex: 1;
  font-size: 20px;
  padding: 8px 12px;
  border-radius: 16px;
  border: none;
  background-color: ${({ theme }) => theme.colors.shapeGray};
  color: ${({ theme }) => theme.colors.text};
  outline: none;
`;

export const Title = styled.div`
  display: flex;
  padding: 0px;
  margin-left: 7.5vh;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.title};

  @media screen and (max-width: 426px) {
    margin-left: 4vh;
  }
`;

export const ContainerCategorySearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 7.5vh;
  padding-bottom: 82px;

  @media screen and (max-width: 426px) {
    margin-left: 4vh;
  }
`;

export const CategorySearch = styled.div`
  display: inline-flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  margin: 5px;
  border-radius: 12px;
  width: 160px;
  height: 200px;
  white-space: pre-wrap;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
`;

export const CategoryImageSearch = styled.img`
  background-color: #fff;
  margin: 5px;
  height: 150px;
  width: 150px;
  border-radius: 10px;
`;

export const CategoryTitleSearch = styled.div`
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text};
`;

export const ContainerProducts = styled.div`
  padding-bottom: 82px;
`;
