import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const HeaderAccount = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const HeaderAccountItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: ${({ theme, selected }) => (selected ? theme.colors.main : theme.colors.textSecondary)};
`;

export const HeaderAccountItemIcon = styled.div`
  display: flex;
`;

export const HeaderAccountItemName = styled.div`
  font-size: 12px;
`;

export const ContainerSubTabs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`;
