import * as S from '../styles';
import { useTheme } from 'styled-components';
import { moeda } from 'utils/functions';
import { MdPlayArrow } from 'react-icons/md';
type Addittional = {
  preco: number;
  precoMontagem: number;
  label: string;
  amount?: number;
  quantidade?: number;
};

export const MountingAdditional = ({
  value,
  type,
  qtd,
}: {
  value: string;
  type: 'm' | 'a';
  qtd: number;
}) => {
  const { colors } = useTheme();
  const list: Addittional[] = JSON.parse(value);

  if (list.length > 0) {
    const result =
      type === 'm' ? (
        <>
          <S.MountingProductTitle>Montagens:</S.MountingProductTitle>
          {list?.map((product, index) => (
            <S.MountingDescription>
              <MdPlayArrow size={15} color={colors.main} />
              {`${index + 1} - ${product.label} - ${
                product.quantidade
              }x : ${moeda(product.precoMontagem)}`}
            </S.MountingDescription>
          ))}
        </>
      ) : (
        <>
          <S.MountingProductTitle>Adicionais:</S.MountingProductTitle>
          {list?.map((product, index) => (
            <S.MountingDescription>
              <MdPlayArrow size={15} color={colors.main} />
              {`${index + 1} - ${product.label} - ${product.amount} X ${moeda(
                product.preco,
              )}= ${moeda(product.preco * (product?.amount || 1))}`}
            </S.MountingDescription>
          ))}
        </>
      );
    return result;
  }
};
