import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  width: 370px;
  height: 90px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
`;

export const PoroductImageWrapper = styled.div`
  height: 90px;
  width: 100px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
`;

export const ProductImage = styled.img`
  height: 90px;
  max-width: 100px;
  border-radius: 5px;
`;

export const ContainerProductInfo = styled.div`
  margin: 0px 5px;
  max-width: calc(100% - 120px);
`;

export const ProductDescription = styled.div`
  font-weight: bold;
  font-size: 14px;
  height: 1.2rem;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  
  color: ${({ theme }) => theme.colors.text};

  @media screen and (max-width: 426px) {
    font-size: 12px;
  }
`;

export const ProductUnit = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const ProductComposition = styled.div`
  font-size: 0.57rem;
  font-weight: 500;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.textSecondary};
  height: 0.8rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.15rem;
`;

export const ProductContainerPrice = styled.div`
  display: flex;
  font-size: 12px;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text};
`;

export const ProductPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductPrice = styled.div`
  color: ${({ promotion, theme }) =>
    promotion ? theme.colors.success : theme.colors.text};
  font-weight: 600;
  font-size: 16px;
  margin-right: 5px;
`;

export const ProductUnavailable = styled.div`
  color: #ff0000;
  font-weight: 600;
`;

export const PromotionPrice = styled.span`
  color: ${({ theme }) => theme.colors.success};
  font-weight: 600;
  font-size: 16px;
  margin-right: 5px;
`;

export const PromotionOldPrice = styled.span`
  text-decoration: line-through;
  font-weight: 600;
  opacity: 0.7;
  font-size: 14px;
`;
