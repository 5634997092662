import { Text } from '../../components/Text/Text';
import * as S from './styles';
import { useForgotPasswordScreen } from './useForgotPasswordScreen';
import { BackButton } from '../../components/ButtonBack';
import Input from '../../components/Input';
import { Spacer } from '../../components/Spacer';
import Button from '../../components/Button';
import Loading from '../../components/Loading';

export const ForgotPassword = () => {
  const {
    loading,
    sentEmail,
    isValidCode,
    onChange,
    navigation,
    onSubmitPass,
    onSubmitEmail,
    onSubmitConfirmationCode,
  } = useForgotPasswordScreen();

  if (loading) {
    return (
      <S.Container>
        <Loading />
      </S.Container>
    );
  }
  return (
    <S.Container>
      <BackButton onClick={navigation.goBack} />
      <S.Content>
        <Text variant="sub_title" style={{ alignSelf: 'center' }}>
          Esqueci minha senha
        </Text>
        <Spacer y={12} />

        {!sentEmail && (
          <>
            <Text variant="text">
              Insira seu email para receber o codigo de confirmação
            </Text>

            <Input
              placeholder="email@email.com"
              onChange={onChange('email')}
              type="email"
            />

            <Button onClick={onSubmitEmail}>Continuar</Button>
          </>
        )}

        {sentEmail && !isValidCode && (
          <>
            <Text variant="text">Insira o codigo de confirmação</Text>

            <Input
              onChange={onChange('confirmationCode')}
              placeholder="xxxxxx"
            />

            <Button onClick={onSubmitConfirmationCode}>Continuar</Button>
          </>
        )}

        {isValidCode && (
          <>
            <Text variant="text">Insira uma nova senha</Text>

            <Input
              onChange={onChange('pass')}
              placeholder="Nova senha"
              type="password"
            />

            <Input
              onChange={onChange('confirmPass')}
              placeholder="Confirme a senha"
              type="password"
            />

            <Button onClick={onSubmitPass}>Continuar</Button>
          </>
        )}
      </S.Content>
    </S.Container>
  );
};
