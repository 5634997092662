import React, { useCallback, useEffect, useRef } from 'react';
import * as S from './styles';
import { moeda } from '../../../../utils/functions';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { FallbackImage } from '../../../../components/FallbackImage';

export const MountItem = ({
  index,
  mount,
  addMounting,
  remMounting,
  handleImage,
  handleIsDisabled,
  checkMountings,
  mountingsRefs,
  additionalRef,
}) => {
  const mountRef = useRef(null);
  const currentCheckMount = checkMountings?.filter(
    (x) => x.idMontagem === mount.IdMontagemPrato,
  );

  const quantitySelected = currentCheckMount.reduce(
    (acc, curr) => acc + curr.quantidade,
    0,
  );

  const verifyMaxSelect = useCallback(() => {
    const currentMountRef = mountingsRefs?.current?.[index];
    if (!currentMountRef) {
      return;
    }

    const amountSelected = currentCheckMount?.reduce(
      (acc, curr) => (acc += curr?.quantidade || 0),
      0,
    );

    const nextMountRef = mountingsRefs?.current?.[index + 1];

    if (nextMountRef?.current && amountSelected >= mount.QtdMax) {
      nextMountRef?.current?.scrollIntoView({ behavior: 'smooth' });
      mountingsRefs.current = mountingsRefs?.current?.map((item, i) =>
        index !== i ? item : null,
      );
    }

    if (
      !nextMountRef?.current &&
      !!additionalRef?.current &&
      amountSelected >= mount.QtdMax
    ) {
      additionalRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mount, checkMountings, mountingsRefs, index]);

  useEffect(() => {
    if (mountRef?.current && !mountingsRefs?.current?.includes(mountRef)) {
      mountingsRefs?.current?.push(mountRef);
    }
  }, []);

  useEffect(() => {
    verifyMaxSelect();
  }, [checkMountings]);

  return (
    <S.ItemContainer ref={mountRef} id={mount.DescricaoMontagem}>
      <S.ItemsHeaderContainer>
        <S.ItemsTitle>{mount.DescricaoMontagem}:</S.ItemsTitle>

        <div>
          <S.ItemsDescription>
            No máx. {mount.QtdMax} opções{' '}
          </S.ItemsDescription>
          {mount.QtdSemCusto > 0 && (
            <S.ItemsDescription>
              Sem custo {mount.QtdSemCusto}{' '}
              {mount.QtdSemCusto > 1 ? 'opções' : 'opção'}
            </S.ItemsDescription>
          )}
          {mount.QtdMin > 0 && (
            <S.ItemsDescription>
              Obrigatório Min. {mount.QtdMin}
            </S.ItemsDescription>
          )}
        </div>
      </S.ItemsHeaderContainer>

      <S.ProductsMountingContainer>
        {currentCheckMount
          .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
          .map((check) => (
            <S.CardContainer key={`check-mounting-${check.id}`}>
              <S.CardImageWrapper>
                {handleImage(check) ? (
                  <S.CardImage
                    src={handleImage(check)}
                    alt={check.label ?? ''}
                  />
                ) : (
                  <FallbackImage size={60} />
                )}
              </S.CardImageWrapper>
              <S.CardContent>
                <S.CardDetails>
                  <S.CardTitle>{check.label}</S.CardTitle>
                </S.CardDetails>
              </S.CardContent>
              <S.CardActionsWrapper>
                {Number(check?.preco) > 0 &&
                  quantitySelected >= mount.QtdSemCusto && (
                    <S.CardPrice> {moeda(check.preco)}</S.CardPrice>
                  )}
                <S.CardActions>
                  {!check.controlaEmbalagem && (
                    <S.ActionIconWrapper
                      onClick={() => remMounting(check, mount)}
                    >
                      <FaMinus color="red" size={20} />
                    </S.ActionIconWrapper>
                  )}
                  <S.CardAmount>{check.quantidade}</S.CardAmount>
                  {!check.controlaEmbalagem && (
                    <S.ActionIconWrapper
                      onClick={() => addMounting(check, mount)}
                      isDisabbled={handleIsDisabled(mount, check)}
                    >
                      <FaPlus color="green" size={20} />
                    </S.ActionIconWrapper>
                  )}
                </S.CardActions>
              </S.CardActionsWrapper>
            </S.CardContainer>
          ))}
      </S.ProductsMountingContainer>
    </S.ItemContainer>
  );
};
