import React from 'react';
import * as S from './styles';

import { descontoPercentual, moeda } from '../../../../utils/functions';
import { useProductCard } from './useProductCard';
import { FallbackImage } from '../../../../components/FallbackImage';

export const Product = ({ product, groupSizes, group, promotion }) => {
  const {
    baseUrl,
    price,
    priceDiscount,
    hasProductSizes,
    smallPrice,
    originalPrice,
    smallerPizzaPrice,
    smallerPizzaWithoutDiscount,
    hasDiscount,
    hasPercentualDiscount,
    percentualDiscount,
    handleClick,
  } = useProductCard({
    groupSizes,
    group,
    product,
    promotion,
  });

  return (
    <S.Product onClick={() => handleClick(product)}>
      <S.ProductImageWrapper>
        {product.Imagem ? (
          <S.ProductImage src={`${baseUrl}${product.Imagem}`} />
        ) : (
          <FallbackImage size={110} />
        )}
      </S.ProductImageWrapper>
      <S.ProductName>{product.Descricao}</S.ProductName>
      {product.TipoProduto === 'INDUSTRIALIZADO' && (
        <>
          <S.ProductPrice fontSize={10}>por</S.ProductPrice>
          {!hasDiscount && !hasPercentualDiscount && (
            <S.ProductPrice>{moeda(price)}</S.ProductPrice>
          )}

          {!!hasDiscount && (
            <S.ProductPrice>
              <S.PromotionPrice>{moeda(priceDiscount)} </S.PromotionPrice>
              <S.PromotionOldPrice>{moeda(price)}</S.PromotionOldPrice>
            </S.ProductPrice>
          )}

          {!!hasPercentualDiscount && (
            <S.ProductPrice>
              <S.PromotionPrice>
                {moeda(descontoPercentual(price, percentualDiscount))}{' '}
              </S.PromotionPrice>
              <S.PromotionOldPrice>{moeda(price)}</S.PromotionOldPrice>
            </S.ProductPrice>
          )}
        </>
      )}

      {!!product.IsPizza && (
        <>
          <S.ProductPrice fontSize={10}>A partir de</S.ProductPrice>
          <S.ProductPrice>
            {!!hasDiscount && (
              <>
                <S.PromotionPrice>{moeda(smallerPizzaPrice)} </S.PromotionPrice>
                <S.PromotionOldPrice>
                  {moeda(smallerPizzaWithoutDiscount)}
                </S.PromotionOldPrice>
              </>
            )}

            {!!hasPercentualDiscount && (
              <>
                <S.PromotionPrice>
                  {moeda(
                    descontoPercentual(smallerPizzaPrice, percentualDiscount),
                  )}{' '}
                </S.PromotionPrice>
                <S.PromotionOldPrice>
                  {moeda(smallerPizzaPrice)}
                </S.PromotionOldPrice>
              </>
            )}

            {!hasDiscount && !hasPercentualDiscount && moeda(smallerPizzaPrice)}
          </S.ProductPrice>
        </>
      )}

      {product.TipoProduto === 'PREPARADO' && !product.IsPizza && (
        <>
          <S.ProductPrice fontSize={10}>
            {hasProductSizes ? 'a partir de ' : 'por '}
          </S.ProductPrice>
          <S.ProductPrice>
            {!hasDiscount && !hasPercentualDiscount && moeda(smallPrice)}

            {!!hasDiscount && (
              <>
                <S.PromotionPrice>
                  {moeda(hasProductSizes ? smallPrice : priceDiscount)}{' '}
                </S.PromotionPrice>
                <S.PromotionOldPrice>
                  {moeda(hasProductSizes ? originalPrice : price)}
                </S.PromotionOldPrice>
              </>
            )}

            {!!hasPercentualDiscount && (
              <>
                <S.PromotionPrice>
                  {moeda(
                    descontoPercentual(
                      hasProductSizes ? smallPrice : price,
                      percentualDiscount,
                    ),
                  )}{' '}
                </S.PromotionPrice>
                <S.PromotionOldPrice>
                  {moeda(hasProductSizes ? smallPrice : price)}
                </S.PromotionOldPrice>
              </>
            )}
          </S.ProductPrice>
        </>
      )}
    </S.Product>
  );
};
