import React from 'react';
import { Container } from './styles';
import { Product } from './Product/Product';

const ListProducts = ({ products, searchProduct, productsSizes = [] }) => {
  const filter = products.filter((prod) => {
    return (
      prod.Descricao.toLowerCase().indexOf(searchProduct.toLowerCase()) !== -1
    );
  });

  return (
    <Container>
      {filter?.map((product) => (
        <Product
          key={product.Id}
          product={product}
          productsSizes={productsSizes}
        />
      ))}
    </Container>
  );
};

export default ListProducts;
