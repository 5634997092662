import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html {
      font-family: 'Poppins', sans-serif;
      background-color:#fafafa ;
      height: 100vh;
    }
    body {
      padding: 0; margin: 0
    }
`;
