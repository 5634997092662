import React, { createContext, useContext, useState } from 'react';

const GroupContext = createContext({
  group: {},
  setGroup: (value = {}) => {
    value;
  },
  previewsPageHome: false,
  setPreviewsPageHome: (value = false) => {
    value;
  },
});

export const GroupProvider = ({ children }) => {
  const [group, setGroup] = useState(null);
  const [previewsPageHome, setPreviewsPageHome] = useState(null);

  return (
    <GroupContext.Provider
      value={{ group, setGroup, previewsPageHome, setPreviewsPageHome }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export function useGroup() {
  const { group, setGroup, previewsPageHome, setPreviewsPageHome } =
    useContext(GroupContext);

  if (
    (typeof group !== 'object' && group !== null && group !== 'promotion') ||
    (typeof previewsPageHome !== 'boolean' && previewsPageHome !== null) ||
    !setGroup ||
    !setPreviewsPageHome
  ) {
    throw new Error('GroupContext must be used within an GroupContext');
  }

  return { group, setGroup, previewsPageHome, setPreviewsPageHome };
}
