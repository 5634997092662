import api from '../../services/api';

export async function getSizesByGroup(groupId: string, groupIsPizza: boolean) {
  if (!groupId || groupId === 'undefined') return [];

  try {
    const querySizes = groupIsPizza
      ? `produto-tamanho/listar_pizzas/${groupId}`
      : `produto-tamanho/listarPorGrupo/${groupId}`;

    const { data } = await api.get(querySizes);

    return data;
  } catch (error) {
    console.log(error);
  }
}
