import React, { useEffect, useState } from 'react';
import { Flavor } from './Flavor';
import {
  ItemsBodyContainer,
  ItemsContainer,
  ItemsDescription,
  ItemsHeaderContainer,
  ItemsTitle,
} from './styles';

const Flavors = ({
  containerRef,
  bordersRef,
  items,
  setItems,
  size,
  sizes,
  isFlavor,
  selectedSize,
  isBorder,
  mountingsRefs,
  additionalRef,
}) => {
  const [qtdFlavor, setQtdFlavor] = useState(null);
  const verifyQtAditional = () => {
    const cont = items.filter((x) => x.check);
    return cont.length;
  };

  const checkAdd = (index, adc) => {
    const cont = verifyQtAditional();
    const qtd = isBorder ? 1 : qtdFlavor;
    const check = cont < qtd ? !adc.check : false;

    if (isFlavor && cont >= qtdFlavor - 1 && !adc.check) {
      bordersRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    if (isBorder && !adc.check) {
      const firstMountRef = mountingsRefs?.current?.at(0);

      if (firstMountRef?.current) {
        firstMountRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }

      if (!firstMountRef?.current && additionalRef.current) {
        additionalRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (qtd === 1) {
      setItems((prev) =>
        prev.map((item, idx) =>
          index !== idx
            ? { ...item, check: false }
            : { ...item, check: !item.check },
        ),
      );
      return;
    }

    setItems((state) =>
      state.map((item, i) => (index !== i ? item : { ...item, check })),
    );
  };

  useEffect(() => {
    if (isFlavor) {
      containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    setItems((state) => state.map((item) => ({ ...item, check: false })));

    const sizeFlavorQtt = sizes?.find((s) => s.Descricao === size)?.QtdSabores;

    setQtdFlavor(isBorder ? 1 : sizeFlavorQtt ? sizeFlavorQtt - 1 : 0);
  }, [size]);

  return (
    <ItemsContainer
      show={qtdFlavor > 0 || (isBorder && items.length > 0)}
      ref={containerRef}
    >
      <ItemsHeaderContainer>
        <ItemsTitle>{isBorder ? 'Borda' : 'Sabores'}:</ItemsTitle>

        <ItemsDescription>
          {isBorder ? (
            <>(opcional)</>
          ) : (
            <>
              Escolha mais {qtdFlavor} {qtdFlavor > 1 ? 'sabores' : 'sabor'}{' '}
              (opcional)
            </>
          )}
        </ItemsDescription>
      </ItemsHeaderContainer>

      <ItemsBodyContainer>
        {items.map((flavor, index) => (
          <Flavor
            flavor={flavor}
            index={index}
            checkAdd={checkAdd}
            size={size}
            selectedSize={selectedSize}
          />
        ))}
      </ItemsBodyContainer>
    </ItemsContainer>
  );
};

export default Flavors;
