import React from 'react';
import {
  Category,
  CategoryImage,
  CategoryTitle,
  Container,
  ContainerCategory,
  ContainerGroups,
  Group,
  TitleContainer,
} from './styles';
import api from '../../services/api';
import CatalogProducts from './components/Products';
import Loading from '../../components/Loading';
import { FloatCartButton } from '../../components/FloatCartButton';
import { useCatalog } from './useCatalog';
import { FallbackImage } from '../../components/FallbackImage';
import { InitializeFaceBookPixel } from '../../events/FaceBookPixelEvents';
import { useConfig } from '../../hooks/config';

const Catalog = () => {
  const { groups, changeGroup, loading } = useCatalog();
  const baseUrl = api.getUri();
  const { config } = useConfig();
  InitializeFaceBookPixel();

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Container>
      {!!config.MostrarCategoriasInicio && (
        <>
          <TitleContainer>Categorias</TitleContainer>
          <ContainerCategory>
            {groups.map((group) => (
              <Category key={group.Id} onClick={changeGroup(group)}>
                {group.ImagemURL ? (
                  <CategoryImage src={`${baseUrl}${group.ImagemURL}`} />
                ) : (
                  <FallbackImage size={60} style={{ alignSelf: 'center' }} />
                )}
                <CategoryTitle>{group.Descricao}</CategoryTitle>
              </Category>
            ))}
          </ContainerCategory>
        </>
      )}
      <ContainerGroups>
        {!!config.MostrarPromocoesInicio && (
          <Group key={'promotionGroup'}>
            <CatalogProducts grupo={'promotion'} promotion />
          </Group>
        )}
        {groups.map((group) => (
          <Group key={group.Id}>
            <CatalogProducts grupo={group} />
          </Group>
        ))}
      </ContainerGroups>

      <FloatCartButton />
    </Container>
  );
};

export default Catalog;
