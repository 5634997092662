import api from '../../services/api';

import { Product } from '../../entities/Product.entity';
import { Group } from '../../entities/group.entity';
import { Flavor } from '../../entities/flavors.entity';

const getFlavorQuery = (group: Group, companyId: number) => {
  if (group?.ExibirPizzasGrupo) {
    return `produto/listarPizzasPorGrupo/${group.Id}`;
  }

  if (group?.UsaSaboresSelecionados) {
    return `produto/listarPizzasSelecionadas/${companyId}/${group.Id}`;
  }

  return `produto/listarPizzas/${companyId}`;
};

export async function getFlavors(
  product: Product,
  group: Group,
  companySizes: [],
): Promise<Flavor[]> {
  try {
    const companyId = product.IdEmpresa;

    const { data } = await api.get<Product[]>(getFlavorQuery(group, companyId));

    const newflavors: Flavor[] = [];

    data?.forEach((pizza, i) => {
      const viewPizzasGroup = +group.ExibirPizzasGrupo
        ? product.Grupo === pizza.Grupo
        : true;

      if (pizza.Id !== product.Id && viewPizzasGroup) {
        const sizesPrices = companySizes?.filter(
          (s: any) => pizza.Id === s.IdProduto,
        );

        newflavors.push({
          id: i,
          idProd: pizza.Id,
          codigoPDV: pizza.CodigoPDV,
          label: pizza.Descricao,
          image: pizza.Imagem,
          check: false,
          composicao: pizza.Composicao,
          sizesPrices,
          ...pizza,
        });
      }
    });

    return newflavors;
  } catch (error) {
    console.log(error);
    return [];
  }
}
