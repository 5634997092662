import {
  useState,
  useContext,
  createContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import { IdEmpresa } from '../services/api';
import { Group } from '../entities/group.entity';
import { getGroups } from '../httpRequests/group/getGroups';
import { getAddittionalGroups } from '../httpRequests/group/getAddittionalGroups';

type ContextProps = {
  groups: Group[];
  addittionalGroups: Group[];
  loading: boolean;
};

const GroupsContext = createContext<ContextProps>({
  groups: [],
  addittionalGroups: [],
  loading: false,
});

function useGroupsContext() {
  const [groups, setGroups] = useState<Group[]>([]);
  const [addittionalGroups, setAddittionalGroups] = useState<Group[]>([]);
  const [loadings, setLoadings] = useState({
    groups: true,
    addittionalGroups: true,
  });

  const loading = loadings.groups || loadings.addittionalGroups;

  const loadGroups = () => {
    getGroups(IdEmpresa).then((data) => {
      setGroups(data);
      setLoadings((state) => ({ ...state, groups: false }));
    });

    getAddittionalGroups().then((data) => {
      setAddittionalGroups(data);
      setLoadings((state) => ({ ...state, addittionalGroups: false }));
    });
  };

  useEffect(() => {
    loadGroups();
  }, []);

  return { groups, addittionalGroups, loading };
}

export function GroupsProvider({ children }: PropsWithChildren) {
  const data = useGroupsContext();
  return (
    <GroupsContext.Provider value={data}>{children}</GroupsContext.Provider>
  );
}

export function useGroups() {
  const context = useContext(GroupsContext);
  return context;
}
