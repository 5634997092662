import React from 'react';
import { TbError404 } from 'react-icons/tb';
import { ErrorContainer, DescriptionError, IconError } from './styles';

const PageNotFound = () => {
  return (
    <ErrorContainer>
      <IconError>
        <TbError404 size={70} />
      </IconError>
      <DescriptionError>Pagina não encontrada</DescriptionError>
    </ErrorContainer>
  );
};
export default PageNotFound;
