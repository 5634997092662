import axios from 'axios';
import { useCallback, useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdOutlineSearch } from 'react-icons/md';
import { mask, unMask } from 'remask';
import Button from '../../../../components/Button';
import { BackButton } from '../../../../components/ButtonBack';
import Dropdown from '../../../../components/Dropdown';
import Input from '../../../../components/Input';
import InputButton from '../../../../components/InputButton';
import Title from '../../../../components/Title';
import api, { IdEmpresa } from '../../../../services/api';
import { notification } from '../../../../utils/notifications';
import {
  ContainerAdd,
  ContainerAddAddress,
  ContainerTitle,
  FormAddress,
} from './styles';
import { useAuth } from '../../../../hooks/auth';
import { Spacer } from '../../../../components/Spacer';

const AddAddress = ({
  address,
  setMyAddress,
  config,
  neighborhoods,
  closeModal,
}) => {
  const [neighborhoodState, setNeighborhoodState] = useState('');
  const { user } = useAuth();

  const formRef = useRef(null);

  const searchCep = useCallback(() => {
    const { street, city, uf, cep } = formRef.current;

    if (!cep.value) {
      return notification('warn', 'Informe o CEP');
    }

    const Cep = cep.value.replace(/\D+/g, '');

    axios
      .get(`https://viacep.com.br/ws/${Cep}/json/`)
      .then(({ data }) => {
        if (!data.localidade || !data.uf) {
          return notification('error', 'CEP não encontrado');
        }
        street.value = data.logradouro ?? '';
        city.value = data.localidade;
        uf.value = data.uf;
      })
      .catch((err) => {
        console.error(err);
        notification('error', 'Não foi possivel procurar CEP.');
      });
  }, [formRef.current?.cep]);

  const backToTop = useCallback(() => {
    const e = document.getElementById('topo');
    e.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, []);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    const schema = Yup.object().shape({
      IdUser: Yup.string().required(),
      IdEmpresa: Yup.number().required(),
      Descricao: Yup.string().required('Descrição obrigatória'),
      Cep: Yup.string()
        .required('Cep obrigatório')
        .min(9, 'Cep incompleto')
        .max(9, 'Cep ultrapassou o limite'),
      Logradouro: Yup.string().required('Endereço obrigatório'),
      Numero: Yup.string().required('Número obrigatório'),
      Bairro: Yup.string().required('Bairro obrigatório'),
      Complemento: Yup.string(),
      PontoRef: Yup.string(),
      Cidade: Yup.string().required('Cidade obrigatória'),
      Uf: Yup.string().required('Estado obrigatório'),
    });

    const {
      description,
      street,
      number,
      neighborhood,
      complement,
      reference,
      city,
      cep,
      uf,
    } = formRef.current;

    schema
      .validate({
        IdUser: user.Id,
        IdEmpresa: IdEmpresa,
        Descricao: description.value,
        Cep: cep.value,
        Logradouro: street.value,
        Numero: number.value,
        Bairro: neighborhood.value,
        Complemento: complement.value,
        PontoRef: reference.value,
        Cidade: city.value,
        Uf: uf.value,
        Ativo: 0,
      })
      .then((validated) => {
        if (address.Id) {
          api
            .patch(`/endereco/alterar/${address.Id}`, validated)
            .then((response) => {
              setMyAddress((state) =>
                state.map((e) => {
                  if (e.Id !== response.data.Id) {
                    return e;
                  }
                  e = response.data;
                  return e;
                }),
              );
              notification('success', 'Endereço alterado');
              closeModal();
            });
        } else {
          api.post('/endereco/criar', validated).then((response) => {
            setMyAddress((state) => [...state, response.data]);
            notification('success', 'Endereço cadastrado');
            closeModal();
          });
        }
      })
      .catch((err) => {
        notification('warn', err.errors[0]);
      });
  }, []);

  useEffect(() => {
    backToTop();
    if (address.Id) {
      const {
        description,
        street,
        neighborhood,
        number,
        complement,
        reference,
        city,
        uf,
        cep,
      } = formRef.current;
      description.value = address.Descricao;
      cep.value = address.Cep;
      street.value = address.Logradouro;
      neighborhood.value = address.Bairro;
      number.value = address.Numero;
      complement.value = address.Complemento;
      reference.value = address.PontoRef;
      city.value = address.Cidade;
      uf.value = address.Uf;
    }
  }, []);

  return (
    <ContainerAdd id="topo">
      <BackButton onClick={closeModal} />
      <ContainerAddAddress>
        <ContainerTitle>
          <FaMapMarkerAlt size={30} color={config.Tema} />
          <Title>{address.Id ? 'Editar endereço' : 'Cadastrar endereço'}</Title>
        </ContainerTitle>
        <FormAddress ref={formRef} onSubmit={handleSubmit}>
          <Input placeholder="Minha casa, escritório..." name="description" />
          <InputButton
            placeholder="Cep"
            name="cep"
            iconButton={<MdOutlineSearch />}
            onChange={(e) => {
              formRef.current.cep.value = mask(unMask(e.target.value), [
                '99999-999',
              ]);
            }}
            onClick={searchCep}
          />
          <Input placeholder="Endereço" name="street" />
          <Dropdown
            list={neighborhoods}
            objListId="Id"
            objListLabel="Bairro"
            name="neighborhood"
            value={neighborhoodState}
            onChange={(e) => setNeighborhoodState(e.target.value)}
          />
          <Input placeholder="Número" name="number" />
          <Input placeholder="complemento" name="complement" />
          <Input placeholder="Ponto referência" name="reference" />
          <Input placeholder="Cidade" name="city" />
          <Input placeholder="Estado" name="uf" />
          <Spacer y={24} />
          <Button type="submit">Salvar</Button>
        </FormAddress>
      </ContainerAddAddress>
    </ContainerAdd>
  );
};

export default AddAddress;
