import styled from "styled-components";

export const ContainerButtonSeeMore = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 110px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
    transition: 0.2s;
  }
`;

export const ButtonSeeMoreIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  background-color: #bcbcbc;
  border-radius: 50px;
  color: #fff;
  font-size: 40px;
`;

export const ButtonSeeMoreText = styled.div`
  margin: 5px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
`;

export const NotProductFound = styled.div`
  display: flex;
  align-items: center;
`;

export const GroupProducts = styled.div`
    display: flexbox;
    overflow: auto;
    white-space: nowrap;
    align-items: center;
`;

export const GroupTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
`;
