import { Product } from '../../entities/Product.entity';
import api, { IdEmpresa as companyId } from '../../services/api';

export async function getProducts() {
  try {
    if (!companyId) {
      return [];
    }

    const { data } = await api.get<Product[]>(`produto/listar/${companyId}`);

    if (data && Array.isArray(data)) {
      return data;
    }

    return [];
  } catch (error) {
    console.log(error);

    return [];
  }
}
