import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ProductSize } from '../entities/ProductSize.entity';
import { getSizesByCompany } from '../httpRequests/sizes/getSizesByCompany';
import { IdEmpresa } from '../services/api';

type TSizesContext = {
  sizes: ProductSize[];
  loading: boolean;
};

const SizesContext = createContext<TSizesContext>({
  loading: false,
  sizes: [],
});

function useSizesContext(): TSizesContext {
  const [loading, setLoading] = useState(true);
  const [sizes, setSizes] = useState<ProductSize[]>([]);

  const loadSizes = useCallback(async (abortSignal: AbortSignal) => {
    setLoading(true);

    const data = await getSizesByCompany(IdEmpresa, abortSignal);

    if (data) {
      setSizes(data);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    loadSizes(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, []);

  return { sizes, loading };
}

export function SizesProvider({ children }: PropsWithChildren) {
  const value = useSizesContext();
  return (
    <SizesContext.Provider value={value}>{children}</SizesContext.Provider>
  );
}

export const useSizes = () => useContext(SizesContext);
