import socketIO from 'socket.io-client';

export function openSocket() {
  const url = process.env.REACT_APP_API_URL;

  if (!url) {
    return null;
  }

  return socketIO(url);
}
