import { Spacer } from '../../../../components/Spacer';
import { FallbackImage } from '../../../../components/FallbackImage';
import CheckBox from '../../../../components/CheckBox';

import { descontoPercentual, moeda } from '../../../../utils/functions';
import api from '../../../../services/api';
import { FlavorProps } from './types';

import {
  ItemDescription,
  ItemDescriptionWrapper,
  ItemImage,
  ItemImageWrapper,
  ItemLabel,
  ItemPrice,
  ItemPriceWrapper,
  Items,
} from './styles';

export const Flavor = ({
  flavor,
  index,
  checkAdd,
  size,
  selectedSize,
}: FlavorProps) => {
  const baseUrl = api.getUri();

  const getSizePrice = (): number => {
    const borderSizePrice = flavor?.tamanhos?.find(
      (t: any) => t.IdTamanho === selectedSize?.IdTamanho,
    );
    const pizzaSizePrice = flavor.sizesPrices?.find(
      (sp) => sp.Descricao === size,
    );

    const sizePrice = borderSizePrice || pizzaSizePrice;

    if (flavor.HasDesconto) {
      return sizePrice?.PrecoDesconto;
    }

    if (flavor.HasDescontoPercentual) {
      const value = descontoPercentual(
        sizePrice?.Preco,
        flavor.DescontoPercentual,
      );
      return value;
    }

    return sizePrice?.Preco;
  };

  return (
    <Items key={flavor.id} onClick={() => checkAdd(index, flavor)}>
      <ItemImageWrapper>
        {flavor?.image ? (
          <ItemImage
            src={`${baseUrl}${flavor.image}`}
            alt={flavor?.label ?? ''}
          />
        ) : (
          <FallbackImage size={60} />
        )}
      </ItemImageWrapper>

      <ItemDescriptionWrapper>
        <ItemLabel>{flavor.label}</ItemLabel>
        <ItemDescription>{flavor.composicao}</ItemDescription>
      </ItemDescriptionWrapper>
      {!!Number(flavor?.preco) && (
        <ItemPriceWrapper>
          <ItemPrice>{moeda(flavor.preco)}</ItemPrice>
        </ItemPriceWrapper>
      )}

      {Number(getSizePrice()) > 0 && (
        <ItemPriceWrapper>
          <ItemPrice>{moeda(getSizePrice())}</ItemPrice>
        </ItemPriceWrapper>
      )}
      <Spacer x={10} />
      <CheckBox
        checked={flavor?.check ?? false}
        placeholder={undefined}
        value={undefined}
        onChange={undefined}
        disabled={undefined}
      />
    </Items>
  );
};
