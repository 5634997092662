import React from 'react';

export const Spacer = ({ x = 0, y = 0, flex = 0 }) => {
  return (
    <div
      style={{
        height: y,
        width: x,
        flexGrow: flex ? 1 : 0,
      }}
    />
  );
};
