import React from 'react';
import { Item, List } from './styles';

const Dropdown = ({
  list,
  objListId,
  objListLabel,
  value,
  name,
  onChange,
  nameDefaultOption,
}) => {
  return (
    <List value={value ?? ''} onChange={onChange} name={name}>
      <Item value="">{nameDefaultOption ?? 'Selecione..'}</Item>
      {list.map((item, index) => (
        <Item key={index} value={item[objListId]}>
          {item[objListLabel]}
        </Item>
      ))}
    </List>
  );
};

export default Dropdown;
