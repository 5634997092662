import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 40px;
  background-color: ${({ theme }) => theme.colors.background};

  @media screen and (max-width: 426px) {
    margin-left: 0px;
  }
`;
