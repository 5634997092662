import { ChangeEvent, useRef, useState } from 'react';
import { useTab } from '../../hooks/tabIndex';
import { notifications } from 'utils/notifications';
import {
  requestForgotPassword,
  sendConfirmationCode,
  sendNewPassword,
} from './services';
import { validateCode, validateEmail, validatePass } from './utils';

const defaultFormState = {
  email: '',
  confirmationCode: '',
  pass: '',
  confirmPass: '',
};

const defaultLoadingState = {
  email: false,
  code: false,
  password: false,
};

export const useForgotPasswordScreen = () => {
  const navigation = useTab();

  const [form, setForm] = useState(defaultFormState);
  const [sentEmail, setSentEmail] = useState(false);
  const [isValidCode, setIsValidCode] = useState(false);
  const [loadings, setLoadings] = useState(defaultLoadingState);

  const loading = loadings?.code || loadings?.email || loadings?.password;

  const onChange = (label: keyof typeof form) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setForm((state) => ({ ...state, [label]: event.target.value }));
    };
  };

  const changeLoading = (loadingProps: Partial<typeof loadings>) => {
    setLoadings((state) => ({ ...state, ...loadingProps }));
  };

  const onSubmitEmail = async () => {
    changeLoading({ email: true });
    try {
      const validatedEmail = await validateEmail(form.email);

      const success = await requestForgotPassword(validatedEmail);

      setSentEmail(success);
    } catch (error) {
      if (error && typeof error === 'object' && 'message' in error) {
        notifications.error(error.message);
      }
    } finally {
      changeLoading({ email: false });
    }
  };

  const onSubmitConfirmationCode = async () => {
    changeLoading({ code: true });
    try {
      const confirmationCode = await validateCode(form.confirmationCode);

      const valid = await sendConfirmationCode(confirmationCode, form.email);

      setIsValidCode(valid);
    } catch (error) {
      if (error && typeof error === 'object' && 'message' in error) {
        notifications.error(error.message);
      }
    } finally {
      changeLoading({ code: false });
    }
  };

  const onSubmitPass = async () => {
    changeLoading({ password: true });
    try {
      const { pass, confirmPass } = form;

      const validatedData = await validatePass({ pass, confirmPass });

      const { success } = await sendNewPassword(
        validatedData.pass,
        form.email,
        form.confirmationCode,
      );

      if (success) {
        navigation.reset();
        notifications.success('Senha atualizada');
      }
    } catch (error) {
      if (error && typeof error === 'object' && 'message' in error) {
        notifications.error(error.message);
      }
    } finally {
      changeLoading({ password: false });
    }
  };

  return {
    sentEmail,
    isValidCode,
    loading,
    navigation,
    onChange,
    onSubmitEmail,
    onSubmitConfirmationCode,
    onSubmitPass,
  };
};
