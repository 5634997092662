import React from 'react';
import { PropsWithChildren } from 'react';
import { providers } from './constants';

export const Providers = ({ children }: PropsWithChildren) => {
  return (
    <>
      {providers.reduceRight(
        (acc, Provider) => (
          <Provider>{acc}</Provider>
        ),
        children,
      )}
    </>
  );
};
