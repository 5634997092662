import { notification } from '../../utils/notifications';
import { User } from '../../entities/user.entity';
import api from '../../services/api';
import { AxiosError } from 'axios';

export async function updateUser(
  id: string,
  form: Partial<User>,
): Promise<void> {
  try {
    await api.patch(`/usuario/alterar/${id}`, form);

    notification('success', 'Informações alteradas');
  } catch (error) {
    console.log(error);

    if (error instanceof AxiosError) {
      notification('error', error?.response?.data.message);
    }
  }
}
