import React from 'react';
import Input from '../../../components/Input';
import { useConfig } from '../../../hooks/config';
import { FinishFormTitle } from '../styles';
import { ContainerAddressInsert } from './styles';
import { useCart } from '../../../hooks/useCart';
import { Spacer } from '../../../components/Spacer';
import { Select } from '../../../components/Select/Select';

const AddressInsert = ({
  address,
  setAddress,
  number,
  setNumber,
  complement,
  setComplement,
  neighborhoods,
  neighborhoodId,
  setNeighborhoodId,
  setTaxeDelivery,
  setTaxeFree,
  taxeFreeDeliveryRegion,
}) => {
  const { config } = useConfig();
  const { total, cart } = useCart();

  const selectNeighborhood = (b) => {
    let taxe = 0,
      free = false;

    if (!b) {
      return;
    }
    taxe = b.taxaEntrega > 0 && b.cobraTaxa === 'S' ? b.taxaEntrega : 0;

    if (config.FreteGratis && config.FreteGratis > 0) {
      if (total >= parseFloat(config.FreteGratis)) {
        taxe = 0;
        free = true;
      }
    } else if (taxeFreeDeliveryRegion.length > 0) {
      taxeFreeDeliveryRegion.forEach((region) => {
        if (
          region.IdBairro === b.value &&
          total >= parseFloat(region.TaxaGratis)
        ) {
          taxe = 0;
          free = true;
        }
      });
    }
    const productFreeDelivery = cart.find((x) => x.freteGratis);
    if (productFreeDelivery) {
      taxe = 0;
      free = true;
    }
    setNeighborhoodId(b);
    setTaxeDelivery(taxe);
    setTaxeFree(free);
  };

  return (
    <ContainerAddressInsert>
      <FinishFormTitle color={config.Tema}>
        Endereço para entrega
      </FinishFormTitle>

      <Select
        options={neighborhoods}
        value={neighborhoodId}
        onChange={(newValue) => selectNeighborhood(newValue)}
        placeholder="Selecione o bairro de entrega"
      />

      <Spacer y={8} />

      <Input
        placeholder="Informe a Rua/Av."
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />

      <Spacer y={8} />

      <Input
        placeholder="Número"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
      />

      <Spacer y={8} />

      <Input
        placeholder="Complemento"
        value={complement}
        onChange={(e) => setComplement(e.target.value)}
      />
    </ContainerAddressInsert>
  );
};

export default AddressInsert;
