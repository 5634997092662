import { PropsWithChildren, createContext } from 'react';
import { Product } from '../entities/Product.entity';
import { useProductsContext } from '../hooks/useProducts';

type ProductsContextProps = {
  products: Product[];
};

export const ProductsContext = createContext<ProductsContextProps>({
  products: [],
});

export const ProductsProvider = ({ children }: PropsWithChildren) => {
  const value = useProductsContext();
  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};
