import { commonColors } from './commonColors';

export const lightTheme = {
  isDark: false,
  colors: {
    ...commonColors,

    background: '#ffffff',
    backgroundSecondary: '#f8f8f8',

    text: '#111111',
    textSecondary: '#282828',
    title: '#000000',

    line: '#a1a1a1',

    shapeGray: '#d7d7d7',
  },
};
