import api, { IdEmpresa as companyId } from '../../services/api';

export async function getMountingProducts() {
  try {
    const endpoint = `/montagem-produtos/listarProdutosMontagem/${companyId}`;

    const res = await api.get<any[]>(endpoint);

    if (res.data && Array.isArray(res.data)) {
      return res.data;
    }

    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
}
