import { commonColors } from './commonColors';

export const darkTheme = {
  isDark: true,
  colors: {
    ...commonColors,

    background: '#111111',
    backgroundSecondary: '#222222',

    text: '#fdfdfd',
    textSecondary: '#a1a1a1',
    title: '#FFFFFF',

    line: '#EBEBF0',

    shapeGray: '#414141',
  },
};
