import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const List = styled.div`
    display: flex;
    width: 100%;
    overflow-x: scroll;
`;
