import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 4;
`;

export const ButtonBackContainer = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
`;

export const CoupomContainer = styled.div`
  /* display: ${(props) => (props.open ? 'block' : 'none')}; */
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
`;

export const CoupomOptionsContainer = styled.div`
  margin: 50px 0px;
  display: flex;
  width: 100%;
  justify-content: space-around;
`;
