import React, { useCallback, useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { useConfig } from '../../hooks/config';
import { useGroup } from '../../hooks/group';
import { IdEmpresa } from '../../services/api';
import ListProducts from '../../components/ListProducts';
import {
  Container,
  ContainerInfoProducts,
  ContainerInputSearch,
  DescriptionInfoProducts,
  Header,
  InputSearch,
} from './styles';
import { BackButton } from '../../components/ButtonBack';
import { FloatCartButton } from '../../components/FloatCartButton';
import { getProductsByGroup } from '../../httpRequests/products/getProductsByGroup';
import { getSizesByGroup } from '../../httpRequests/sizes/getSizesByGroup';
import { getPromotionProducts } from '../../httpRequests/products/getPromotionProducts';
import { getSizesByCompany } from '../../httpRequests/sizes/getSizesByCompany';

const ProductsGroup = () => {
  const { config } = useConfig();
  const { group, setGroup } = useGroup();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState('');
  const [productsSizes, setProductsSizes] = useState([]);

  const listPromotionProducts = async () => {
    const data = await getPromotionProducts(IdEmpresa);
    setProducts((prev) => data || prev);
  };

  const listGroupProducts = async () => {
    const data = await getProductsByGroup(group.Id, IdEmpresa, 999);
    setProducts((prev) => data || prev);
  };

  const listProductsSizes = async () => {
    const data =
      group === 'promotion'
        ? await getSizesByCompany(IdEmpresa)
        : await getSizesByGroup(group.Id, group.IsPizza);

    setProductsSizes((prev) => data || prev);
  };

  const atualizar = useCallback(async () => {
    setLoading(true);
    try {
      listProductsSizes();

      if (group === 'promotion') {
        listPromotionProducts();
      } else {
        listGroupProducts();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [group.Id, IdEmpresa]);

  useEffect(() => {
    atualizar();
  }, []);

  return (
    <Container>
      <Header>
        <ContainerInputSearch>
          <BackButton
            onClick={() => setGroup(null)}
            style={{ padding: 2, margin: 0 }}
          />
          <InputSearch
            placeholder="Buscar"
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
          />
        </ContainerInputSearch>
      </Header>
      {loading && <Loading loading={loading} />}
      {!loading && (
        <>
          <ContainerInfoProducts>
            <DescriptionInfoProducts>
              {products.length} produtos na categoria{' '}
              {group === 'promotion'
                ? 'Promoções'
                : group.Descricao?.toUpperCase()}
            </DescriptionInfoProducts>
          </ContainerInfoProducts>
          <ListProducts
            products={products}
            config={config}
            searchProduct={searchProduct}
            productsSizes={productsSizes}
          />
        </>
      )}
      <FloatCartButton hasTab={false} />
    </Container>
  );
};

export default ProductsGroup;
