import { Addittional } from '../../../../entities/Addittional.entity';
import { Group } from '../../../../entities/group.entity';

export const mapStateReset = (additional: Addittional) => {
  return {
    ...additional,
    check: false,
    amount: 0,
  };
};

export const mapStateIncrement = (additionalItem: Addittional) => {
  return (additional: Addittional) => {
    if (additional.id !== additionalItem.id) return additional;

    return {
      ...additional,
      check: true,
      amount: additional.amount + 1,
    };
  };
};

export const mapStateDecrement = (additionalItem: Addittional) => {
  return (additional: Addittional) => {
    if (additional.id !== additionalItem.id) return additional;

    const amount = additional.amount > 0 ? additional.amount - 1 : 0;

    return {
      ...additional,
      check: amount > 0,
      amount: amount,
    };
  };
};

export function getMaxQuantity(addittionalGroups: Group[], groupId?: string) {
  return addittionalGroups.find((group) => group.Id === groupId)?.QtdMaxima;
}
