import React from 'react';
import * as S from './styles';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useTheme } from 'styled-components';

export const Pagination = ({ page, next = () => {}, prev = () => {} }) => {
  const { colors } = useTheme();

  const handlePrev = () => {
    if (page !== 1) {
      prev();
    }
  };
  const handleNext = () => {
    next();
  };

  if (!page) {
    return null;
  }

  return (
    <S.Container>
      <S.Prev onClick={handlePrev}>
        <FiArrowLeft color={colors.text} />
      </S.Prev>
      <S.Page>{page}</S.Page>
      <S.Next onClick={handleNext}>
        <FiArrowRight color={colors.text} />
      </S.Next>
    </S.Container>
  );
};
