import { getDateByDateAndTime } from './getDate';

export const orderByDateTime = (data = []) => {
  return data?.sort((data_a, data_b) => {
    const date_a = getDateByDateAndTime(data_a.DataPedido, data_a.Hora);
    const date_b = getDateByDateAndTime(data_b.DataPedido, data_b.Hora);

    if (date_a > date_b) return -1;
    if (date_a < date_b) return 1;
    return 0;
  });
};
