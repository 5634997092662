import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 10px;
  margin: 8px;
  background: #363636;
  color: #fff;
  font-size: 40px;
  border-radius: 50%;
  cursor: pointer;

  &:active {
    background: ${shade(0.4, '#363636')};
  }
`;
