import { AxiosError } from 'axios';
import api from '../../services/api';

export async function getOrderProduct(orderId: string): Promise<any | null> {
  try {
    const { data } = await api.get(`/pedido-produto/encontrar/${orderId}`);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.log(
        `Erro ao carregar seus produtos. ${error?.response?.data.message}`,
        error,
      );
    }
    return null;
  }
}
