import { MouseEventHandler, useState } from 'react';
import { MdLogin } from 'react-icons/md';
import { mask, unMask } from 'remask';
import {
  Container,
  ContainerLogin,
  ContainerSignUp,
  ContainerTitle,
  DescriptionConsult,
  TextLink,
  Title,
} from './styles';
import { login } from '../../utils/functions';
import { useTab } from '../../hooks/tabIndex';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { notification } from '../../utils/notifications';
import { useAuth } from '../../hooks/auth';
import { useTheme } from 'styled-components';
import { Spacer } from '../../components/Spacer';
import { screens } from 'routes/Tabs/screens';
import { useConfig } from 'hooks/config';

const Login = () => {
  const { colors } = useTheme();
  const { setTab } = useTab();
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useAuth();
  const { config } = useConfig();

  const sign: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    if (!phone || !password) {
      notification('error', 'Preencha todos os campos');
      return;
    }

    const signed = await signIn(phone, password);

    if (!signed || !signed?.signed || !signed?.user?.Ativo) {
      return;
    }

    login(btoa(signed?.user?.Id));
    setTab(screens.account);
  };

  return (
    <Container>
      <ContainerLogin>
        <ContainerTitle>
          <MdLogin color={colors.main} size={40} />
          <Title>Faça seu login</Title>
        </ContainerTitle>
        <Input
          placeholder="Celular: (xx) 9xxxx-xxxx"
          value={phone}
          onChange={(e) =>
            setPhone(mask(unMask(e.target.value), ['(99)99999-9999']))
          }
        />
        <Input
          placeholder="Senha"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Spacer y={24} />
        <Button onClick={sign}>Entrar</Button>
        {config.CanSignUp && (
          <Button
            onClick={() => setTab(screens.create_account)}
            variant="ghost"
          >
            Cadastrar-se
          </Button>
        )}
        <ContainerSignUp>
          <DescriptionConsult>
            <TextLink onClick={() => setTab(screens.forgot_password)}>
              Esqueci minha senha
            </TextLink>
          </DescriptionConsult>
          <DescriptionConsult>
            Consultar pedidos sem cadastro clique{' '}
            <TextLink onClick={() => setTab(screens.consult_order)}>
              aqui
            </TextLink>
          </DescriptionConsult>
        </ContainerSignUp>
      </ContainerLogin>
    </Container>
  );
};

export default Login;
