import styled from 'styled-components';

export const ItemsContainer = styled.div`
  margin-top: 10px;
  overflow: scroll;
  border-radius: 4px;
  max-height: ${({ show }) => (show ? 'auto' : '0px')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

export const ItemsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.shapeGray};
  padding: 6px 10px;
`;

export const ItemsTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
`;

export const ItemsDescription = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.text};
`;

export const AddButton = styled.button`
  border: none;
  background-color: #003366;
  padding: 4px 7px;
  border-radius: 4px;
`;

export const ItemsBodyContainer = styled.div`
  padding: 5px;
`;

export const Items = styled.div`
  display: flex;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.main : 'transparent'};
  margin: 10px 0;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
`;

export const ItemImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 6px;
  margin-right: 6px;
  overflow: hidden;
`;

export const ItemImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ItemDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ItemLabel = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: 500;
`;

export const ItemDescription = styled.span`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 10px;
`;

export const ItemPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemPrice = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: 500;
`;
