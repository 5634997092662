import styled from 'styled-components';

export const ContainerAdd = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  z-index: 1;
  background: #fff;
  flex-direction: column;
  height: 154vh;

  @media screen and (min-width: 426px) {
    height: 111vh;
  }
`;

export const ContainerAddAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const FormAddress = styled.form`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 10px 0px;
  gap: 12px;
`;
