import { Product } from 'entities/Product.entity';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

type ProductContextType = {
  product: Product | null;
  setProduct: (newState: Product | null) => void;
};

const ProductContext = createContext<ProductContextType>({
  product: null,
  setProduct: (newState: Product | null) => {},
});

export const ProductProvider = ({ children }: PropsWithChildren) => {
  const [product, setProduct] = useState<Product | null>(null);

  const changeProduct = (newState: Product | null) => {
    setProduct(newState);
  };

  return (
    <ProductContext.Provider value={{ product, setProduct: changeProduct }}>
      {children}
    </ProductContext.Provider>
  );
};

export function useProduct() {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('ProductContext must be used within an ProductContext');
  }
  return context;
}
