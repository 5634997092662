import { useCallback, useEffect, useState } from 'react';
import { useGroup } from '../../../../hooks/group';
import { IdEmpresa } from '../../../../services/api';
import { getProductsByGroup } from '../../../../httpRequests/products/getProductsByGroup';
import { getSizesByGroup } from '../../../../httpRequests/sizes/getSizesByGroup';
import { getPromotionProducts } from '../../../../httpRequests/products/getPromotionProducts';

export const useCatalogProducts = ({ group, promotion }) => {
  const [produtos, setProdutos] = useState([]);
  const { setGroup } = useGroup();
  const { width } = window.screen;
  const [groupSizes, setGroupSizes] = useState([]);

  const getCatalogProducts = async () => {
    const getProducts = () => promotion ?
      getPromotionProducts(IdEmpresa) :
      getProductsByGroup(group.Id,
        IdEmpresa,
        width > 820 ? 8 : 4,
      );

    const data = await getProducts();
    setProdutos(data);
  };

  const atualizar = useCallback(async () => {
    getCatalogProducts();

    const resposeSizes = await getSizesByGroup(group.Id, group.IsPizza);
    setGroupSizes(resposeSizes);
  }, [IdEmpresa, group, getProductsByGroup, getSizesByGroup]);

  useEffect(() => {
    atualizar();
  }, []);

  return {
    produtos,
    width,
    groupSizes,
    setGroup,
  };
};
