import { shade } from "polished";
import styled from "styled-components";

export const FinishedButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 22px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.main};
  border-radius: 28px;
  cursor: pointer;
  box-shadow: 0px 4px 8px -3px #000000;

  &:active {
    background: ${({ theme }) => shade(0.2, theme.colors.main)};
    transition: 0.2s;
  }
`;

export const FinishedButtonText = styled.div`
  font-size: 18px;
  color: #fff;
  font-weight: bold;
`;
