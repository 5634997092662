import api from '../../services/api';

export async function getSizesByProduct(productId: string) {
  try {
    if (!productId || productId === 'undefined') return [];

    const querySizes = `produto-tamanho/listarPorProduto/${productId}`;

    const { data } = await api.get(querySizes);

    return data;
  } catch (error) {
    console.log(error);
  }
}
