import React from 'react';

import { MdArrowForward, MdArrowForwardIos, MdOutlineHideSource } from 'react-icons/md';
import { Product } from '../Product/Product';
import { useCatalogProducts } from './useCatalogProducts';

import * as S from './styles';
import { useTheme } from 'styled-components';
import { useConfig } from '../../../../hooks/config';

const CatalogProducts = ({ grupo, promotion }) => {
  const { produtos, width, setGroup, groupSizes } = useCatalogProducts({
    group: grupo,
    promotion,
  });
  const { colors } = useTheme();
  const { config } = useConfig();

  if (!grupo || produtos.length < 1) {
    return null;
  }

  return (
    <>
      <S.GroupTitle
        onClick={() => setGroup(grupo)}
      >
        <b>{promotion ? 'Promoções' : grupo.Descricao}</b>{' '}
        <MdArrowForwardIos color={config.Tema} size={22} />
      </S.GroupTitle>
      <S.GroupProducts>
        {produtos.map((produto) => (
          <Product
            key={produto.Id}
            product={produto}
            groupSizes={groupSizes}
            group={grupo}
            promotion
          />
        ))}

        {produtos.length >= (width > 820 ? 8 : 4) && (
          <S.ContainerButtonSeeMore
            onClick={() => {
              setGroup(grupo);
            }}
          >
            <S.ButtonSeeMoreIcon>
              <MdArrowForward color={colors.title} />
            </S.ButtonSeeMoreIcon>
            <S.ButtonSeeMoreText>Ver Todos</S.ButtonSeeMoreText>
          </S.ContainerButtonSeeMore>
        )}
        {produtos.length === 0 && (
          <S.NotProductFound>
            <MdOutlineHideSource />
            Sem Produtos
          </S.NotProductFound>
        )}
      </S.GroupProducts>
    </>
  );
};

export default CatalogProducts;
