import api, { IdEmpresa as companyId } from '../../services/api';

function mapData(_border: any, i: number) {
  return {
    id: i,
    idProd: _border.Id,
    codigoPDV: +_border.CodigoPDV,
    label: _border.Descricao,
    image: _border.Imagem,
    tamanhos: _border.tamanhos,
    group: _border.Grupo,
    isBorder: true,
    check: false,
  };
}

export async function getBorderByCompany() {
  try {
    const res = await api.get(`/produto/listarBordasPorCompany/${companyId}`);

    if (!res.data || !Array.isArray(res.data)) {
      return [];
    }

    return res.data.map(mapData);
  } catch (error) {
    console.log(error);
    return [];
  }
}
