import { useContext, useEffect, useState } from 'react';
import { ProductsContext } from '../contexts/products.context';
import { Product } from '../entities/Product.entity';
import { getProducts } from '../httpRequests/products/getProducts';

export const useProductsContext = () => {
  const [products, setProducts] = useState<Product[]>([]);

  const fetchProducts = async () => {
    const data = await getProducts();
    setProducts(data);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return { products };
};

export const useProducts = () => {
  const context = useContext(ProductsContext);

  if (!context) {
    throw new Error('ProductsContext must be used within an ProductsProvider');
  }

  return context;
};
