import api, { IdEmpresa as companyId } from '../../services/api';
import { Group } from '../../entities/group.entity';

export async function getAddittionalGroups() {
  try {
    if (!companyId || Number.isNaN(Number(companyId))) {
      return [];
    }

    const { data } = await api.get<Group[]>(
      `/grupo-produto/listarAdicionais/${companyId}`,
    );

    if (!Array.isArray(data)) {
      return [];
    }

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
}
