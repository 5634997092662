import * as S from '../styles';

export const Flavors = ({ data }: { data: string }) => {
  const flavors: { check: boolean; label: string }[] = JSON.parse(data);

  return (
    <>
      {flavors.map((flavor) =>
        flavor.check ? (
          <S.FlavorProduct key={`flavor-${flavor.label}`}>
            <S.LabelProduct>+ Sabor: </S.LabelProduct>
            <S.ValueProduct>{flavor.label}</S.ValueProduct>
          </S.FlavorProduct>
        ) : null,
      )}
    </>
  );
};
