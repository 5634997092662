import React, { CSSProperties } from 'react';
import {
  FaHamburger,
  FaPizzaSlice,
  FaWineBottle,
  FaCookieBite,
} from 'react-icons/fa';
import { Container, Content } from './styles';

const colors_pallete = [
  '#9BF6AC',
  '#E3E4E8',
  '#BBD8DA',
  '#B9B2DB',
  '#A5DAE6',
  '#BDE4E5',
  '#FEE3B8',
];

export const FallbackImage = React.memo(
  ({ size = 100, style = {} }: { size?: number; style?: CSSProperties }) => {
    const colorIndex = Math.floor(Math.random() * colors_pallete.length);
    const backgroundColor = colors_pallete[colorIndex];

    const icons = (() => {
      const arrayIcons = [];
      const factor = size / 2.5;
      const length = Math.floor((size / factor) * (size / factor));

      for (let i = 0; i < length; i += 1) {
        arrayIcons.push(FaPizzaSlice, FaHamburger, FaWineBottle, FaCookieBite);
      }
      return arrayIcons;
    })();

    return (
      <Container
        style={{
          backgroundColor,
          ...style,
        }}
        size={size}
      >
        <Content size={size}>
          {icons.map((Icon) => (
            <Icon size={size / 8} color="#fff" />
          ))}
        </Content>
      </Container>
    );
  },
);
