import { Order } from '../../entities/Order.entity';
import api from '../../services/api';
import * as yup from 'yup';

export async function getOrder(orderId: string): Promise<Order | null> {
  try {
    const validation = yup.string().uuid();
    await validation.validate(orderId);

    const { data } = await api.get<Order>(`/pedido/encontrar/${orderId}`);

    if (!data) {
      throw new Error(`Order ${orderId} not found`);
    }

    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
