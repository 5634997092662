import styled from 'styled-components';

export const ContainerCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputCheckProp = styled.input`
  width: 1.25rem;
  height: 1.25rem;
`;

export const LabelCheckProp = styled.label`
  font-size: 0.7rem;
  font-weight: 500;
`;
