import GlobalStyle from './styles/global';
import Rotas from './routes';
import { Notifications } from 'react-push-notification';
import { Providers } from './providers/Providers';
import 'App.css';

const App = () => {
  return (
    <>
      <Notifications position="top-right" />
      <Providers>
        <Rotas />
      </Providers>
      <GlobalStyle />
    </>
  );
};
export default App;
