import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Tabs from './Tabs';
import PageNotFound from '../pages/PageNotFound';

const Rotas = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/:id" element={<Tabs />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Rotas;
